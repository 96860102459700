import React, { useState } from 'react';
import { makeStyles, Box, useMediaQuery, DialogContent, DialogActions } from '@material-ui/core';
import Text from '../../../common/text.component';
import EmailForm from './EmailForm.component';
import { theme as globalTheme } from '../../../testUtils';
import { LoadingMessage } from '../../../common/LoadingModal.component';
import CloseIcon from '@material-ui/icons/Close';
import FlashMessage from '../../../common/FlashMessage.component';
import Button from '../../../common/button.component';
import Dialog from '../../../common/Dialog.component';
import { validateEmail } from '../../../../utils/validators.utils';
import ActionButtons from '../../../common/ActionButtons.component';

const MODAL_TITLE = 'Enviar solicitud de reserva por email';
const MODAL_LEYEND = 'Ingresar el email de la persona que debe tomar este requerimiento.';
const LOADING_LEYEND = 'Estamos enviando el mail';
const COMFIRM_BTN_TEXT = 'Enviar';
const CONTINUE_BTN_TEXT = 'Continuar navegando';
const SUCCESS_TITLE = 'Mail enviado con éxito.';
// eslint-disable-next-line max-len
const FORM_INITIAL_FIELDS = {
  email: '',
  comment:'',
};
const INITIAL_VALIDATION = {
  valid: true,
  error: '',
};

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      minHeight: '28rem',
      width: '26rem'
    }
  },
  title: {
    display:'block',
    textAlign: 'center',
    fontSize:'1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  leyend: {
    display:'block',
    textAlign: 'center',
    fontSize:'1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '16.25rem',
    minHeight: '3.3rem',
    textTransform: 'none',
    fontSize: '1.25rem',
    justifySelf: 'flex-end',
  },
  buttonContainer: {
    paddingTop: theme.spacing(7),
  },
  closeButton: {
    fontSize: '1.25rem',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const EmailDialog = (
  {
    open,
    onBack,
    onClose,
    loading,
    success,
    onConfirm,
    onContinue,
  }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'));
  const [fields, setFields] = useState(FORM_INITIAL_FIELDS);
  const [error, setError] = React.useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleOnFieldChanges = (newFields) => {
    setFields(newFields);
    setError(INITIAL_VALIDATION);
    formSubmitted && setFormSubmitted(false);
  };

  const handleOnSubmit = () => {
    const isValidEmail = validateEmail(fields.email);
    setError(isValidEmail);
    setFormSubmitted(true);
    isValidEmail.valid && onConfirm(fields);
  };

  const handleClose = () => {
    clearDialog();
    if(success) {
      onBack();
    } else {
      onClose();
    }
  };

  const clearDialog = () => {
    setFields( { email: '', comment: '' } );
  };
  const disableConfirmButton = !!!fields.email;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.dialog} dividers>
        { !fullScreen ? (
          <CloseIcon className={classes.closeButton} onClick={handleClose}/>
        ) : null }
        { loading ?
          <LoadingMessage
            leyend={LOADING_LEYEND} />
          : success ?
            <FlashMessage
              onContinue={onBack}
              success
              succesTitle={SUCCESS_TITLE}
              continueButtonText={CONTINUE_BTN_TEXT}
              fullScreen={fullScreen}
            />
            :
            <Box>
              <Text bold className={classes.title}>{MODAL_TITLE}</Text>
              <Text light className={classes.leyend}>{MODAL_LEYEND}</Text>
              <EmailForm
                error={error}
                submitted={formSubmitted}
                fields={fields}
                onFieldChanges={handleOnFieldChanges} />
              { !fullScreen &&
              <Box className={classes.buttonContainer}>
                <ActionButtons
                  disableConfirmButton={disableConfirmButton}
                  onCancel={onContinue}
                  confirmText={COMFIRM_BTN_TEXT}
                  onConfirm={handleOnSubmit}
                />
              </Box>
              }
            </Box>
        }
      </DialogContent>
      { fullScreen && !loading &&
      <DialogActions>
        {!success ?
          (
            <ActionButtons
              disableConfirmButton={disableConfirmButton}
              onCancel={onContinue}
              confirmText={COMFIRM_BTN_TEXT}
              onConfirm={handleOnSubmit}
            />
          ) :
          (
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                data-test-id="confirmar-reserva-dialogBtn"
                onClick={onBack}
                outline>
                {CONTINUE_BTN_TEXT}
              </Button>
            </Box>
          )
        }
      </DialogActions>}
    </Dialog>
  );
};

export default EmailDialog;
