/* eslint-disable max-len */
import { theme as globalTheme } from '../../../testUtils';
import { ReserveStatus } from '../../system/ReserveStatus.enum';

const getCellStyles = (rowData) => {
  let style;
  switch (rowData) {
    case ReserveStatus.PENDING:
      style = { color: globalTheme.palette.error.main };
      break;
    case  ReserveStatus.APPROVED:
      style = { color: globalTheme.palette.success.main };
      break;
    case  ReserveStatus.IN_REVIEW:
      style = { color: globalTheme.palette.info.main };
      break;
    default:
      style = { color: globalTheme.palette.grayScale.g300 };
      break;
  }
  return style;
};

export const desktopColumns = [
  { title: 'Solicitante', field: 'applicant' },
  { title: 'Marca', field: 'brand' },
  { title: 'Modelo', field: 'model' },
  { title: 'Versión', field: 'version' },
  { title: 'Descripción', field: 'description' },
  { title: 'Estado', field: 'status', cellStyle: rowData => getCellStyles(rowData) },
];
export const mobileColumns = [
  { title: 'Descripción', field: 'description' },
];
