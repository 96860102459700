import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Divider,
  useMediaQuery,
  DialogActions,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton
} from '@material-ui/core';
import Link from '../../../common/Link.component';
import Text from '../../../common/text.component';
import Card from '../../../common/Card.component';
import { ReserveStatus } from '../../system/ReserveStatus.enum';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { formatPrice } from '../../../../utils/currency.utils';
import Button from '../../../common/button.component';
// eslint-disable-next-line max-len
import ReservationFinalizationDialogContainer from '../../../../containers/reservation/ReservaFinalizationDialog.container';
import ReservationEmailDialogContainer from '../../../../containers/reservation/ReservaEmailDialog.container';
import { useTheme } from '@material-ui/core/styles';
import LoginSection from '../../../header/LoginSection.component';
import formatDate from '../../../../utils/DateFomatter.utils';

const DATA_LABEL = 'Datos de la reserva';
const BUTTON_PENDING_LABEL = 'Enviar mail';
const BUTTON_IN_REVIEW_LABEL = 'Finalizar';
const TITLE = 'Reservas';

const buttonLabel = (status) => {
  if (status === ReserveStatus.PENDING) {
    return BUTTON_PENDING_LABEL;
  } else if(status === ReserveStatus.IN_REVIEW) {
    return BUTTON_IN_REVIEW_LABEL;
  }
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize:'1rem'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.grayScale.g0,
    color: theme.palette.secondary.main,
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    alignItems:'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '35rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem'
    }
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '2rem 1rem 0',
  },
  sectionClient: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '2rem 1rem 2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sectionData: {
    flex:1,
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
    }
  },
  pending: {
    color: theme.palette.error.main
  },
  approved: {
    color: theme.palette.success.main
  },
  cancelled: {
    color: theme.palette.grayScale.g400
  },
  inReview: {
    color: theme.palette.info.main
  },
  link: {
    minHeight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'center',

    '& button': {
      width: '100%'
    }
  },
  seccionBotton: {
    marginBottom: '2rem',
  },
  adicionalClass1:{
    flexGrow:1
  },
  adicionalClass2:{
    flexGrow:2
  }
}));

const SectionData = ({ label, value, adicionalClass = '' }) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.sectionData} ${adicionalClass}`}
      display='flex'
      flexDirection="column"
      boxSizing="border-box"
    >
      <Box marginBottom="0.5rem">
        <Text bold>{label}</Text>
      </Box>
      <div>
        <Text light small className={clsx( {
          [classes.pending]: value === ReserveStatus.PENDING,
          [classes.approved]: value === ReserveStatus.APPROVED,
          [classes.cancelled]: value === ReserveStatus.CANCELLED,
          [classes.inReview]: value === ReserveStatus.IN_REVIEW,
        } )}>{value || '--'}</Text>
      </div>
    </Box>
  );
};

const ReservationCard = ({ reserve }) => {
  const classes = useStyles();
  return <Card className={classes.card}>
    <Box className={classes.section}>
      <SectionData label="Solicitante" value={reserve.applicant} adicionalClass={classes.adicionalClass2} />
      <SectionData label="Tipo solicitante" value={reserve.applicantType} adicionalClass={classes.adicionalClass1}/>
    </Box>
    <Box className={`${classes.section} ${classes.seccionBotton}`}>
      <SectionData  label="Email" value={reserve.email}  adicionalClass={classes.adicionalClass2} />
      <SectionData label="Fecha de reserva" value={reserve.createdAt} adicionalClass={classes.adicionalClass1} />
    </Box>
    <Divider />
    <Box className={`${classes.section} ${classes.seccionBotton}`}>
      <SectionData label="Marca" value={reserve.brand} />
      <SectionData label="Modelo" value={reserve.model} />
      <SectionData label="Versión" value={reserve.version} />
    </Box>
    <Divider />
    <Box className={`${classes.section} ${classes.seccionBotton}`}>
      <SectionData label="Color" value={reserve.color} />
      {reserve.condition === 0 && <SectionData label="Empresa" value={reserve.branchOffice} /> }
      <SectionData label="Concesionaria" value={reserve?.carlotName} />
    </Box>
    <Divider />
    <Box className={`${classes.section} ${classes.seccionBotton}`}>
      <SectionData label="Precio" value={formatPrice(reserve.price || 0, reserve.currencySimbol)} />
      {reserve.condition === 0 && <SectionData label="Patente" value={reserve.patent} /> }
      <Box className={classes.adicionalClass1}></Box>
    </Box>
    <Divider />
    <Box margin="1rem 0 0 1rem">
      <Text bold>{DATA_LABEL}</Text>
    </Box>
    <Box className={classes.sectionClient}>
      <SectionData label="Nombre y apellido del cliente" value={reserve.client} />
      <SectionData label="Teléfono" value={reserve.numberPhone} />
    </Box>
    <Divider />
    <Box className={classes.sectionClient}>
      <SectionData label="Estado" value={reserve.status} />
      {reserve.status === ReserveStatus.CANCELLED &&
        <SectionData label="Motivo de cancelación" value={reserve.reason} />
      }
    </Box>
  </Card>;
};

const ReservationDetail = ( { reservation, onGoBack, user } ) => {
  const classes = useStyles();
  const [openFinalizationDialog, setOpenFinalizationDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const status = ReserveStatus[reservation.state];
  const formattedReserve = {
    applicant: `${reservation.applicantFirstName} ${reservation.applicantLastName}`,
    email: reservation.applicantEmail,
    carlotName: reservation?.carLot?.name,
    condition: reservation.condition,
    brand: reservation.brand,
    model: reservation.model,
    version: reservation.version.name,
    color: reservation.vehicleColor,
    price: reservation.price,
    client: `${reservation.clientFirstName} ${reservation.clientLastName}`,
    numberPhone: reservation.numberPhone,
    reason: reservation.reasonForCancellation,
    status,
    patent: reservation.patent,
    branchOffice: reservation.branchOffice,
    currencySimbol: reservation.currencySimbol,
    applicantType : reservation.applicantType,
    createdAt: formatDate(new Date(reservation.createdAt),'DateTime')
  };
  function handleButtonClick(statusAction) {
    if (statusAction === ReserveStatus.IN_REVIEW) {
      setOpenFinalizationDialog(true);
    } else if (statusAction === ReserveStatus.PENDING) {
      setOpenEmailDialog(true);
    }
  }

  return (
    <>
      {isMobile ?
        <>
          <Dialog
            fullScreen={true}
            scroll="paper"
            open={true}
            onClose={onGoBack}
            disableEscapeKeyDown
          >
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={onGoBack}
                  className={classes.menuButton}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <Text light className={classes.title}>
                  {TITLE}
                </Text>
              </Toolbar>
              <LoginSection isDesktop isLoggedIn profile={user.profile}/>
            </AppBar>
            <DialogContent dividers>
              <ReservationCard reserve={formattedReserve}/>
            </DialogContent>
            { (status === ReserveStatus.PENDING || status === ReserveStatus.IN_REVIEW) &&
            <DialogActions className={classes.dialogActions}>
              <Button primary onClick={() => handleButtonClick(status)}>{buttonLabel(status)}</Button>
            </DialogActions>
            }
          </Dialog>
        </> :
        <>
          <Box className={classes.link}>
            <Link inverted onClick={onGoBack}>
              <Box display="flex" alignItems="center">
                <ArrowBackIosIcon style={{ fontSize: 10 }}/>
                <Text small>Volver</Text>
              </Box>
            </Link>
            { (status === ReserveStatus.PENDING || status === ReserveStatus.IN_REVIEW) &&
            <Button primary onClick={() => handleButtonClick(status)}>{buttonLabel(status)}</Button> }
          </Box>
          <ReservationCard reserve={formattedReserve}/>
        </>
      }
      { openFinalizationDialog &&
      <ReservationFinalizationDialogContainer
        open={openFinalizationDialog}
        reserve={reservation}
        onClose={() => {setOpenFinalizationDialog(false);}}
        onBack={onGoBack}
      />
      }
      { openEmailDialog &&
      <ReservationEmailDialogContainer
        open={openEmailDialog}
        id={reservation.id}
        onClose={() => {setOpenEmailDialog(false);}}
        onBack={onGoBack}
      />
      }
    </>
  );
};

export default ReservationDetail;
