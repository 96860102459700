import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Select from '../../../../common/Select.component';
import { ReserveStatus } from '../../../system/ReserveStatus.enum';

const LABEL_BRAND = 'Marca';
const LABEL_MODEL = 'Modelo';
const LABEL_STATE = 'Estado';

const useStyles = makeStyles( theme => ({
  reservationFilters: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '19px',
      paddingBottom: '18px',
      paddingLeft: '14px',
      paddingRight: '14px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(0.875rem, 1.125rem) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0.5rem, -1rem) scale(0.75)',
      },
    }
  },
}));

const ReservationSelectors = ({
  initialValues,
  states = [],
  brands = [],
  models = [],
  loadingStates = false,
  loadingBrands = false,
  loadingModels = false,
  onSelectState = () => {},
  onSelectBrand = () => {},
  onSelectModel = () => {},
}) => {
  const classes = useStyles();

  return(
    <Grid container direction='row' spacing={2} className={classes.reservationFilters} >
      <Grid item xs={12} md={4}>
        <Select
          id='select-brand'
          label={LABEL_BRAND}
          loading={loadingBrands}
          onChange={onSelectBrand}
          options={brands.map((brnd) => ({
            label: brnd.name,
            value: brnd,
            key: brnd.id
          }))}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          id='select-model'
          label={LABEL_MODEL}
          loading={loadingModels}
          onChange={onSelectModel}
          disabled={!initialValues.brandName}
          options={models.map((mod) => ({
            label: mod.name,
            value: mod.name,
            key: mod.id
          }))}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          id='select-state'
          label={LABEL_STATE}
          value={initialValues.state}
          loading={loadingStates}
          onChange={onSelectState}
          options={states.map( (state) => ({
            label: ReserveStatus[state],
            value: state,
          }))}/>
      </Grid>
    </Grid>
  );
};


export default ReservationSelectors;
