import React from 'react';
import PropTypes from 'prop-types';
import { Grid,  Box,FormHelperText, makeStyles, Checkbox } from '@material-ui/core';
import Text from '../../../common/text.component';
import TextField from '../../../common/TextField.component';
import Select from '../../../common/Select.component';
import Dropzone from '../../../common/Dropzone.component';
import DropzoneInfo from '../../../common/DropzoneInfo.component';
import _ from 'lodash';

const TITLE = 'Complete todos los datos de la entidad de financiación.';
const COMPANY_NAME_LABEL = 'Nombre de compañía financiera';
const BRANDS_LABEL = 'Marcas';
const VISIBLE_LABEL = '¿Visible en la página web?';
const LOGO_DROPZONE_LABEL = 'Logo de la entidad financiera';
const LOGO_DROPZONE_TITLE  = 'Subí una foto';
const LOGO_DROPZONE_INSTRUCTION  = 'Tambien podés arrastrarla';
const PDF_DROPZONE_LABEL  = 'Pdf de la entidad financiera';
const PDF_DROPZONE_TITLE  = 'Subí un pdf';
const PDF_DROPZONE_INSTRUCTION  = 'Tambien podés arrastrarlo';

const useStyles = makeStyles( theme => ({
  title: {
    fontSize:'1.2rem',
    display:'block',
    margin:`${theme.spacing(4)}px 0`,
    marginTop: 0,
    [theme.breakpoints.up('md')]:{
      maxWidth:'40%'
    }
  },
  inputContainer:{
    [theme.breakpoints.up('md')]:{
      paddingRight: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom: theme.spacing(4),
    }
  },
  input:{
    width:'100%',
    marginBottom: theme.spacing(4)
  },
  switchLabel:{
    fontSize:'1rem'
  },
  dropzone:{
    marginTop:theme.spacing(1),
    paddingTop:theme.spacing(1),
  },
  dropzoneContainer:{
    [theme.breakpoints.up('md')]:{
      height: '100%',
      width:'90%',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:theme.spacing(4),
    },
    margin:'auto',
  },
  fullHeight: {
    [theme.breakpoints.up('md')]:{
      height: '100%',
    },
  }
}));


const FinancingForm = ({
  loadingBrands,
  brands,
  title,
  validations,
  fields,
  onFieldChange,
  onLogoDropzoneError,
  onPdfDropzoneError
}) => {
  const classes = useStyles();
  const logoValidations = {
    accept:['image/gif', 'image/png', 'image/jpeg', 'image/bmp'],
    maxSize:1000000
  };
  const pdfValidations = {
    accept:['application/pdf'],
    maxSize:10000000
  };

  return (
    <Box>
      <Title title={title}/>
      <Box display="flex">
        <Grid container>
          <Grid item xs={12} md={5}>
            <Box className={classes.inputContainer}>
              <CompanyNameInput
                error={!validations.name?.valid}
                helperText={validations.name?.error}
                name={fields?.name}
                onChange={(value) => onFieldChange('name', value)}/>
              <BrandsSelect
                error={!validations.brands?.valid}
                helperText={validations.brands?.error}
                loadingBrands={loadingBrands}
                initialBrands={fields?.brands}
                onChange={(value) => onFieldChange('brands', value)}
                brands={brands}
              />
              <VisibleSwitch
                active={fields?.active}
                onChange={(evt, value) => onFieldChange('active', value) }/>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} className={classes.fullHeight}>
            <Box className={classes.fullHeight}>
              <Grid container className={classes.fullHeight}>
                <Grid item xs={12} md={6} className={classes.fullHeight}>
                  <FinancingDropZone
                    title={LOGO_DROPZONE_TITLE}
                    instruction={LOGO_DROPZONE_INSTRUCTION}
                    onError={onLogoDropzoneError}
                    validations={logoValidations}
                    error={!validations.logo?.valid}
                    helperText={validations.logo?.error}
                    label={LOGO_DROPZONE_LABEL}
                    onChange={(value) => onFieldChange('logo', value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.fullHeight}>
                  <FinancingDropZone
                    title={PDF_DROPZONE_TITLE}
                    instruction={PDF_DROPZONE_INSTRUCTION}
                    onError={onPdfDropzoneError}
                    validations={pdfValidations}
                    error={!validations.pdf?.valid}
                    helperText={validations.pdf?.error}
                    label={PDF_DROPZONE_LABEL}
                    onChange={(value) => onFieldChange('pdf', value)}/>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const Title = ({ title }) => {
  const classes = useStyles();
  return ( <Text bold className={classes.title}>{title}</Text> );
};

const CompanyNameInput = ({
  onChange,
  name,
  error,
  helperText
}) => {
  const classes = useStyles();

  const handleOnChange = (evt) =>  {
    onChange(evt.target.value);
  };

  return (
    <TextField
      error={error}
      helperText={error && helperText}
      className={classes.input}
      value={name}
      data-test-id="input-company-name"
      label={COMPANY_NAME_LABEL}
      onChange={handleOnChange}
      variant="outlined"
    />
  );
};

const BrandsSelect = ({
  loadingBrands,
  onChange,
  brands,
  error,
  helperText,
  initialBrands = []
}) => {
  const classes = useStyles();

  const handleRenderValue = (selectedBrands) => {
    return selectedBrands
      .map( (brand) => brand.name)
      .join(', ');
  };

  const handleOnChange = (selectedBrands) => {
    onChange(selectedBrands);
  };

  const generateValues = () => {
    return brands.filter(
      (brand) => {
        const identicalBrand = initialBrands
          .find((initialBrand) => _.isEqual(initialBrand, brand));
        return !!identicalBrand;
      }
    );
  };

  const values = generateValues();

  return (
    <Box className={classes.input}>
      <Select
        id='select-brands'
        data-test-id="select-brands"
        error={error}
        label={BRANDS_LABEL}
        loading={loadingBrands}
        renderValue={handleRenderValue}
        multiple
        values={values}
        onChange={handleOnChange}
        options={brands.map((brand) => ({
          label: brand.name,
          value: brand,
        }))} />
      {error &&
          <FormHelperText error variant="outlined">{helperText}</FormHelperText>}
    </Box>
  );
};

const VisibleSwitch = ({ active, onChange }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      alignItems="center" >
      <Text className={classes.switchLabel}>{VISIBLE_LABEL}</Text>
      <Checkbox
        data-test-id="visible-checkBox"
        onChange={onChange}
        color="primary"
        checked={active}
      />
    </Box>
  );
};

const FinancingDropZone = (
  {
    onChange,
    onError,
    label,
    error,
    title,
    instruction,
    validations,
    helperText
  }) => {
  const classes = useStyles();

  const handleOnDrop = (accepted, rejected) => {
    if(accepted.length > 0) {
      onChange(accepted[0]);
    }
    if(rejected.length > 0) {
      onError(rejected[0].errors[0]);
    }
  };
  const handleOnDelete = () => {
    onChange(null);
  };

  const renderDropzoneInfo = (dropzoneTitle,dropzoneInstruction) =>
    (isDragActive) =>
      <DropzoneInfo
        title={dropzoneTitle}
        instruction={dropzoneInstruction}
        isDragActive={isDragActive} />;

  return (
    <Box className={classes.dropzoneContainer}>
      <Dropzone
        {...validations}
        label={label}
        error={error}
        onDrop={handleOnDrop}
        onDelete={handleOnDelete}
        className={classes.dropzone}
      >
        {renderDropzoneInfo(title, instruction)}
      </Dropzone>
      {error &&
          <FormHelperText error variant="outlined">{helperText}</FormHelperText>}
    </Box>);
};



FinancingForm.prototype = {
  loadingBrands: PropTypes.bool,
  brands: PropTypes.array,
  title: PropTypes.nodo,
  validations: PropTypes.object,
  fields: PropTypes.object,
  onFieldChange: PropTypes.func,
  onLogoDropzoneError: PropTypes.func,
  onPdfDropzoneError: PropTypes.func,
};

FinancingForm.defaultProps = {
  loadingBrands: false,
  brands: [],
  title: TITLE,
  validations: {},
  fields: {},
  onFieldChange: () => {},
  onLogoDropzoneError: () => {},
  onPdfDropzoneError: () => {},
};

export default FinancingForm;
