import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Select from '../../../../common/Select.component';

const LABEL_BRAND = 'Marca';
const LABEL_MODEL = 'Modelo';
const LABEL_COMPANY = 'Empresa';
const LABEL_PUBLISHED = 'Publicación';
const LABEL_USER = 'Usuario';

const useStyles = makeStyles( theme => ({
  reservationFilters: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '19px',
      paddingBottom: '18px',
      paddingLeft: '14px',
      paddingRight: '14px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(0.875rem, 1.125rem) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0.5rem, -1rem) scale(0.75)',
      },
    }
  },
  filter: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      flexGrow: 1
    },
  }
}));

const ProductsSelectors = ({
  initialValues,
  companies = [],
  publishedOptions = [],
  brands = [],
  models = [],
  visibilityOptions = [],
  loadingCompanies = false,
  loadingBrands = false,
  loadingModels = false,
  onSelectCompany = () => {},
  onSelectBrand = () => {},
  onSelectModel = () => {},
  onSelectPublished = () => {},
  onSelectUserVisibility = () => {}
}) => {
  const classes = useStyles();

  return(
    <Grid container direction='row' spacing={2} className={classes.reservationFilters} >
      <Grid item className={classes.filter}>
        <Select
          id='select-company'
          data-test-id='select-company'
          label={LABEL_COMPANY}
          value={initialValues.company}
          loading={loadingCompanies}
          onChange={onSelectCompany}
          options={companies.map((c) => ({
            label: c.name,
            value: c,
            key: c.id
          }))}/>
      </Grid>
      <Grid item className={classes.filter}>
        <Select
          id='select-brand'
          data-test-id='select-brand'
          label={LABEL_BRAND}
          value={initialValues.brand}
          loading={loadingBrands}
          onChange={onSelectBrand}
          disabled={!initialValues.company}
          options={brands.map((brnd) => ({
            label: brnd.name,
            value: brnd,
            key: brnd.id
          }))}/>
      </Grid>
      <Grid item className={classes.filter}>
        <Select
          id='select-model'
          data-test-id='select-model'
          label={LABEL_MODEL}
          loading={loadingModels}
          value={initialValues.model}
          onChange={onSelectModel}
          disabled={!initialValues.brand}
          options={models.map((mod) => ({
            label: mod.name,
            value: mod,
            key: mod.id
          }))}/>
      </Grid>
      <Grid item className={classes.filter}>
        <Select
          id='select-published'
          data-test-id='select-published'
          label={LABEL_PUBLISHED}
          value={initialValues.publishCarmuv}
          onChange={onSelectPublished}
          options={publishedOptions.map((p, i) => ({
            label: p.label,
            value: p,
            key: i
          }))}/>
      </Grid>
      <Grid item className={classes.filter}>
        <Select
          id='select-visibility'
          data-test-id='select-visibility'
          label={LABEL_USER}
          value={initialValues.userVisibility}
          onChange={onSelectUserVisibility}
          disabled={!initialValues.publishCarmuv}
          options={visibilityOptions.map((option, optionIndex) => ({
            label: option.label,
            value: option,
            key: optionIndex
          }))}/>
      </Grid>
    </Grid>
  );
};

export default ProductsSelectors;
