import React from 'react';
import { isAuthenticated } from '../../api/authorization';
import { goToHome } from '../../api/navigation';

const PrivateRoute = ({ component: Component, allowed, user, location, ...rest }) => {
  const authorize = isAuthenticated(allowed);
  if (!authorize(user)) {
    goToHome();
    return null;
  }
  return <Component location={location} user={user} {...rest} />;
};
export default PrivateRoute;
