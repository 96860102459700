import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import HiddenOn from '../../common/HiddenOn.component';
import Link from '../../common/Link.component';
import Text from '../../common/text.component';
import Logo from '../../common/logo.component';
import { makeStyles } from '@material-ui/core/styles';
import MenuItems from './MenuItems.component';
import LoginSection from '../../header/LoginSection.component';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { navigationRoutes } from '../../../api/navigationRoutes.enum';

const drawerWidth = 240;
const BUTTON_LABEL = 'Ir al sitio web';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  title: {
    fontSize:'1rem'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.grayScale.g0,
    color: theme.palette.secondary.main,
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    alignItems:'center',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]:{
      borderRight: `1px solid ${theme.palette.grayScale.g200}`
    }
  },
  logo: {
    width: '11rem',
    height: '4rem',
    display: 'flex',
    margin:'auto',
    [theme.breakpoints.up('md')] : {
      flexGrow: 1,
      width: '11rem',
      height: '3.75rem',
    },
  },
  logoContainer: {
    ...theme.mixins.toolbar,
  },
  adminButton: {
    display: 'flex',
    paddingLeft: '1rem',
    height: '2.3rem',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover':{
      '& .MuiSvgIcon-root':{
        color: theme.palette.primary.main,
      },
    },
  },
  adminButtonIcon: {
    color: theme.palette.grayScale.g400,
    marginRight: '0.8rem',
  },
  adminItem: {
    display: 'flex',
    paddingLeft: '1rem',
    height: '2.3rem',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover':{
      '& .MuiSvgIcon-root':{
        color: theme.palette.primary.main,
      },
    },
  },
  adminMenuIcon: {
    marginRight: '2rem',
    color: theme.palette.grayScale.g400,
  },
  adminHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const AdminHeader = (
  {
    title,
    isLoggedIn,
    profile,
    adminMenu,
  }
) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return(
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Text light className={classes.title}>
            {title}
          </Text>
        </Toolbar>
        {isLoggedIn &&
          <Box className={classes.adminHeader}>
            <HiddenOn mobile >
              <Link to={navigationRoutes.HOME}>
                <Box className={classes.adminButton} >
                  <DesktopWindowsIcon className={classes.adminButtonIcon}/>
                  <Text normal>{BUTTON_LABEL}</Text>
                </Box>
              </Link>
            </HiddenOn>
            <LoginSection isDesktop isLoggedIn profile={profile}/>
          </Box>
        }
      </AppBar>
      <Box component='nav' className={classes.drawer} aria-label="mailbox folders">
        <HiddenOn desktop >
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box className={classes.logoContainer}>
              <Link to='/' className={classes.logo}>
                <Logo className={classes.logo}/>
              </Link>
            </Box>
            <Menu items={adminMenu}/>
            <Link to={navigationRoutes.HOME}>
              <Box className={classes.adminItem}>
                <DesktopWindowsIcon className={classes.adminMenuIcon}/>
                <Text normal small>{BUTTON_LABEL}</Text>
              </Box>
            </Link>
          </Drawer>
        </HiddenOn>
        <HiddenOn mobile >
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Box className={classes.logoContainer}>
              <Link to='/' className={classes.logo}>
                <Logo className={classes.logo}/>
              </Link>
            </Box>
            <Menu items={adminMenu}/>
          </Drawer>
        </HiddenOn>
      </Box>
    </>
  );
};

const Menu = ({ items }) => {
  return(
    <Box>
      <MenuItems items={items} />
    </Box>
  );
};

export default AdminHeader;
