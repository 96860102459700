import React, { useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import ApplyFiltersDialog from './ApplyFiltersDialog.component';
import Text from '../../common/text.component';
import TotalResults from '../../common/TotalResults.component';
import { MTableToolbar } from 'material-table';
const FILTER_TITLE = 'Filtrar por:';

const useStyles = makeStyles( theme => ({
  filterTitle: {
    fontSize: '1.2rem',
    display:'block',
    padding: theme.spacing(4),
    paddingBottom: 0
  },
  filterDesktop: {
    [theme.breakpoints.down('sm')]: {
      display:'none'
    },
  },
}));

const FilterToolbar = ({
  isMobile,
  filters,
  onUpdateFilters,
  SelectorContainer,
  FilterTagsComponent,
  ...props
}) => {

  return (
    <>
      {isMobile  ?
        <MobileToolbar
          filters={filters}
          onUpdateFilters={onUpdateFilters}
          SelectorContainer={SelectorContainer}
          FilterTagsComponent={FilterTagsComponent}
          {...props}
        />
        :
        <DesktopToolbar
          filters={filters}
          SelectorContainer={SelectorContainer}
          FilterTagsComponent={FilterTagsComponent}
          {...props}
        />
      }
    </>
  );
};

const DesktopToolbar = ({ filters, SelectorContainer, FilterTagsComponent, ...props })=>{
  const classes = useStyles();

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-betweer">
        <Box flex="2">
          <Box className={classes.filterDesktop}>
            <SelectorContainer />
            <Box >
              <FilterTagsComponent />
            </Box>
          </Box>
        </Box>
        <Box flex="1">
          <MTableToolbar {...props} />
          <TotalResults
            isMobile={false}
            total={props.data.length}
          />
        </Box>
      </Box>
    </>
  );
};

const MobileToolbar = ({ filters, onUpdateFilters, SelectorContainer, FilterTagsComponent, ...props })=>{
  const classes = useStyles();
  const [openDialog, setOpenDialg] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState();

  const handleShowFilters = () =>
  {
    setOpenDialg(true);
  };

  const onCloseDialog = () =>
  {
    setOpenDialg(false);
  };

  const handleOnApplyFilters = () => {
    onUpdateFilters(selectedFilters);
  };

  const handleOnUpdateFilters = (newFilters) => {
    setSelectedFilters(newFilters);
  };

  return (
    <>
      <Box>
        <MTableToolbar {...props} />
        <TotalResults
          isMobile
          total={props.data.length}
          onShowFilters={handleShowFilters}/>
        <FilterTagsComponent />
      </Box>
      <ApplyFiltersDialog
        open={openDialog}
        onClose={onCloseDialog}
        onApplyFilters={handleOnApplyFilters}>
        <Box>
          <Text bold className={classes.filterTitle}>{FILTER_TITLE}</Text>
        </Box>
        <SelectorContainer
          filters={filters}
          updateFilters={handleOnUpdateFilters}
        />
      </ApplyFiltersDialog>
    </>
  );
};

export default FilterToolbar;
