import React from 'react';
import Box from '@material-ui/core/Box';

import Button from '../../../common/button.component';


const CANCEL_BTN_TEXT = 'Cancelar';
const SAVE_BTN_TEXT = 'Guardar';

const FinancingActionButtons = ({ onCancel, onSave, disableSaveButton }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center" >
      <Button
        data-test-id="cancel-financing"
        onClick={() => onCancel()}
        outline
      >
        {CANCEL_BTN_TEXT}
      </Button>
      <Button
        primary
        disabled={disableSaveButton}
        data-test-id="save-financing"
        onClick={() => onSave()}>
        {SAVE_BTN_TEXT}
      </Button>
    </Box>
  );
};

export default FinancingActionButtons;
