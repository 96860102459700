import React from 'react';
import { makeStyles, Box, useMediaQuery, DialogContent, DialogActions } from '@material-ui/core';
import { theme as globalTheme } from '../../../testUtils';
import { LoadingMessage } from '../../../common/LoadingModal.component';
import Button from '../../../common/button.component';
import FlashMessage from '../../../common/FlashMessage.component';
import Dialog from '../../../common/Dialog.component';
import Text from '../../../common/text.component';
import ActionButtons from '../../../common/ActionButtons.component';
import FinancingSumary from './FinancingSumary.component';

const CONTINUE_BTN_TEXT = 'Continuar navegando';
const SUCCESS_TITLE = 'Reserva actualizada con éxito.';
const DIALOG_TITLE = 'Esta a punto de eliminar';


const useStyles = makeStyles(theme => ({
  dialogContent: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      width: '26rem'
    }
  },
  title: {
    display:'block',
    textAlign: 'center',
    fontSize:'1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionButtonsDesktop:{
    width:'18rem',
    marginTop: theme.spacing(4),
    marginLeft:'auto',
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]:{
      display: 'none'
    }
  },
}));

const FinancingDeleteDialog = (
  {
    open,
    financing,
    onClose,
    onConfirm,
    onContinue,
    success,
    submitError,
    loading
  }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'));

  const showFlashMessage =  (success || submitError);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.dialogContent} dividers>
        { loading ?
          <LoadingContent />
          : showFlashMessage ?
            <FlashMessage
              success={success}
              onContinue={onContinue}
              succesTitle={SUCCESS_TITLE}
              fullScreen={fullScreen} />
            :
            <Content
              financing={financing}
              onClose={onClose}
              onConfirm={onConfirm} />
        }
      </DialogContent>
      {
        fullScreen && !loading &&
        <DialogActions>
          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            justifyContent="center">
            { showFlashMessage ?

              <Button
                data-test-id="continue-button"
                onClick={() => onContinue()}
                outline>
                {CONTINUE_BTN_TEXT}
              </Button>
              :
              <ActionButtons
                onCancel={onClose}
                onConfirm={onConfirm} />
            }
          </Box>
        </DialogActions>
      }
    </Dialog>
  );
};

const Content = ({
  financing,
  onClose,
  onConfirm
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      justifyContent="space-betwen">
      <Text className={classes.title} bold>{DIALOG_TITLE}</Text>
      <FinancingSumary financing={financing} />
      <Box className={classes.actionButtonsDesktop}>
        <ActionButtons
          onCancel={onClose}
          onConfirm={onConfirm} />
      </Box>
    </Box>
  );
};

const LoadingContent = () => {
  return (
    <Box
      display='flex'
      justifyContent='center' alignItems='center'>
      <LoadingMessage/>
    </Box>
  );
};

export default FinancingDeleteDialog;
