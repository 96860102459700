import React from 'react';
import { Box } from '@material-ui/core';
import TotalResults from '../../../common/TotalResults.component';
import { MTableToolbar } from 'material-table';

const SuggestionToolbar = (props) => {
  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-betweer">
        <Box flex="1">
          <MTableToolbar {...props} />
          <TotalResults
            isMobile={false}
            total={props.data.length}
          />
        </Box>
      </Box>

    </>
  );
};

export default SuggestionToolbar;
