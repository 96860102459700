
/**
 *
 * @param {Date} data
 * @param {'Date'|'DateTime'} format
 * @returns {String} retorna la fecha formateada segun el tipo formato
 *
 */
export default function formatDate(data,format) {
  const date = [
    padTo2Digits(data.getDate()),
    padTo2Digits(data.getMonth() + 1),
    data.getFullYear(),
  ].join('/');

  const time = [
    padTo2Digits(data.getHours()),
    padTo2Digits(data.getMinutes()),
    padTo2Digits(data.getSeconds())].join(':');

  if(format === 'Date'){
    return date;
  }
  if(format === 'DateTime'){
    return date + ' ' + time;
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}
