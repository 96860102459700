import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/text.component';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core/';


const NAME_LEYEND = 'Compañía financiera';

const usesStyle = makeStyles((theme) => ({
  name:{
    display:'block'
  },
  nameLeyend:{
    display:'block',
    paddingTop: theme.spacing(0.8)
  },
})
);

const CompanyName = ({ name, leyend, ...otherProps }) => {
  const classes = usesStyle();

  return (
    <Box
      textAlign='center'
      {...otherProps}>
      <Text bold medium className={classes.name}>{name}</Text>
      <Text light smaller className={classes.nameLeyend}>{leyend}</Text>
    </Box>

  );
};

CompanyName.prototype = {
  name: PropTypes.node,
  leyend: PropTypes.node,
};

CompanyName.defaultProps = {
  name: '',
  leyend: NAME_LEYEND,
};

export default CompanyName;
