import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Link from '../../../common/Link.component';
import Box from '@material-ui/core/Box';
import Table from '../../system/Table.component';
import Text from '../../../common/text.component';
import Checkbox from '@material-ui/core/Checkbox';
import Image from '../../../common/Image.component';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  viewDetailLink: {
    borderTop: `1px solid ${theme.palette.grayScale.g200}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '2rem',

    '& a': {
      display: 'flex',
      alignItems: 'flex-end'
    }
  },
  image: {
    width: 100,
    height: 100,
    backgroundSize: 'contain',
  },
  mobileDetail: {
    backgroundColor: theme.palette.grayScale.g100,
  }
}));

const FinancingTable = ({
  data = [],
  toolbar,
  initialPage = 0,
  pageSize = 10,
  onSelectFinancing = () => {},
  onDeleteFinancing = () => {}
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const desktopColumns = [
    {
      title: 'Visible',
      field: 'active',
      render: rowData => {
        return <Checkbox
          checked={rowData.active}
          color="primary"
          inputProps={{ 'aria-label': 'visible checkbox' }}
          disabled
        />;
      },
      width: '10%'
    },
    {
      title: 'Logo',
      field: 'logo',
      render: rowData => rowData.logo ? <Image image={rowData.logo} className={classes.image} alt='' /> : '--',
      width: 'auto',
    },
    { title: 'Nombre', field: 'name', width: 'auto' },
    { title: 'Marcas asociadas', field: 'brands', width: 'auto' },
    {
      title: 'Pdf',
      field: 'pdf',
      width: 'auto',
      render: rowData => {
        return rowData.pdf ?
          <Link href={rowData.pdf} target="_blank" rel="noreferrer" underline>{rowData.pdfName}</Link> :
          '--';
      },
    },
    {
      title: 'Actualizado',
      field: 'updatedAt',
    }
  ];

  const mobileColumns = [
    {
      title: 'Visible',
      field: 'active',
      render: rowData => {
        return <Checkbox
          checked={rowData.active}
          color="primary"
          inputProps={{ 'aria-label': 'visible checkbox' }}
          disabled
        />;
      },
      width: '10%',
    },
    { title: 'Nombre', field: 'name', width: 'auto' },
  ];

  const columns = isMobile ? mobileColumns : desktopColumns;

  const detailPanel = isMobile ? [
    {
      render: rowData => {
        return (
          <MobileDetail
            rowData={rowData}
            onSelectFinancing={() => selectFinancing(rowData.id)}
            onDeleteFinancing={() => deleteFinancing(rowData.id)}
          />
        );
      },
      icon: () => <AddIcon />,
      openIcon: () => <RemoveIcon />,
    }
  ] : null;

  const actions = !isMobile ? [
    {
      icon: () => <EditIcon color="primary"/>,
      onClick: (evt, row) => selectFinancing(row.id)
    },
    {
      icon: () => <DeleteIcon color="primary"/>,
      onClick: (evt, row) => deleteFinancing(row.id)
    },
  ] : null;

  function selectFinancing(id) {
    const financing = data.find(d => d.id === id);
    onSelectFinancing(financing);
  }

  function deleteFinancing(id) {
    const financing = data.find(d => d.id === id);
    onDeleteFinancing(financing);
  }

  const tableData = data.map( (financing) => {
    const { brands: brandList, updatedAt: updatedAtRaw , ...otherData } = financing;
    const brandNames = brandList.map(brand => brand.name);
    const brands = brandNames.join(', ');
    const dateObj = new Date(updatedAtRaw);
    const updatedAt = dateObj.toLocaleString('es-AR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const pdfName = financing.pdf?.substring(financing.pdf?.lastIndexOf('/') + 1);


    const tableRow =  {
      brands,
      updatedAt,
      pdfName,
      ...otherData,
    };

    return tableRow;
  }
  );

  return (
    <>
      <Table
        columns={columns}
        detailPanel={detailPanel}
        actions={actions}
        ToolbarComponent={toolbar}
        data={tableData}
        isMobile={isMobile}
        options={{
          initialPage,
          pageSize
        }}
      />
    </>
  );
};

const MobileDetail = ({ rowData, onSelectFinancing, onDeleteFinancing }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mobileDetail}>
      <Box margin="15px">
        <Box><Text bold light>Marcas asociadas</Text></Box>
        <Box><Text>{rowData.brands}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Pdf</Text></Box>
        <Box>
          { rowData.pdf ?
            <Link href={rowData.pdf} target="_blank" rel="noreferrer" underline>{rowData.pdfName}</Link> :
            '--'
          } </Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Actualizado</Text></Box>
        <Box><Text>{rowData.updatedAt}</Text></Box>
      </Box>
      <Box className={classes.viewDetailLink}>
        <Link
          inverted
          onClick={() => onSelectFinancing(rowData.id)}
        >
          <EditIcon fontSize="small"/> <Box>Modificar</Box>
        </Link>
        <Link
          inverted
          onClick={() => onDeleteFinancing(rowData.id)}
        >
          <DeleteIcon fintSize="small" /> <Box>Eliminar</Box>
        </Link>
      </Box>
    </Box>
  );
};

FinancingTable.propTypes = {
  data: PropTypes.array,
  toolbar: PropTypes.func,
  onSelectFinancing: PropTypes.func,
  onDeleteFinancing: PropTypes.func,
};

export default FinancingTable;
