import React, { useState, useEffect } from 'react';
import ProductsTableContainer from './ProductsTable.container';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { insertParam } from '../../api/navigation';
import ProductDetailContainer from './ProductDetail.container';

const DEFAULT_FILTERS = {
  brand: null,
  model: null,
  productId: null,
  publishCarmuv: null,
  userVisibility: null
};

const ProductsPageContainer = ({ location, user }) => {
  const [ filters, setFilters ] = useState(DEFAULT_FILTERS);
  const [ selectedProduct, setSelectedProduct ] = useState(null);
  let { page, pageSize } = queryString.parse(location.search);
  if (page) {
    page = parseInt(page);
  }
  if (pageSize) {
    pageSize = parseInt(pageSize);
  }
  useEffect(() => {
    const filtersFromLocation = queryString.parse(location.search);
    setFilters(filtersFromLocation);
    const params = queryString.parse(location.search);
    setSelectedProduct(params.productId);
  }, [location.search]);

  const navigateWithNewFilters = (newFilters) => {
    navigate(`${location.pathname}?${queryString.stringify(newFilters)}`);
  };

  const handleOnUpdateFilters = (newFilters) => {
    navigateWithNewFilters(newFilters);
  };

  const handleSelectProduct = (product) => {
    updateProduct(product.id);
  };

  const updateProduct = (productId) => {
    insertParam('productId', productId);
    setSelectedProduct(productId);
  };

  const handleGoBack = () => {
    updateProduct(null);
  };

  const handleSave = () => {
    updateProduct(null);
  };

  return (
    <>
      { selectedProduct ?
        <ProductDetailContainer
          user={user}
          productId={selectedProduct}
          onGoBack={handleGoBack}
          onSave={handleSave}/>
        :
        <ProductsTableContainer
          filters={filters}
          initialPage={page}
          pageSize={pageSize}
          onUpdateFilters={handleOnUpdateFilters}
          onSelectProduct={handleSelectProduct}
        />
      }
    </>
  );

};

export default ProductsPageContainer;
