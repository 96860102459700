import React from 'react';
import Chip from './Chip.component';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: theme.palette.secondary.main,
    width: '0.8rem',
  },
  label: {
    padding: '5px 14px 5px 16px',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      fontSize:theme.typography.size.smaller
    }
  },
}));


const Tag = ({ onDelete = () => {}, value, hideCloseButton = false, ...props }) => {
  const classes = useStyles();

  function handleDelete() {
    onDelete(value);
  }

  return (
    <Chip
      classes={{
        label: classes.label
      }}
      value={value}
      deleteIcon={hideCloseButton ? <></> : <ClearOutlinedIcon className={classes.deleteIcon}/> }
      onDelete={handleDelete}
      {...props}
    />
  );
};

export default Tag;
