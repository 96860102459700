import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Text from '../common/text.component';
import ColorBox from '../common/ColorBox.component';
import { formatPrice } from '../../utils/currency.utils';

const LEYEND = 'Comercializado por ';

const useStyles = makeStyles(theme => ({
  price: {
    fontSize: theme.typography.size.large,
  },
  leyend: {
    fontSize: theme.typography.size.small,
    display: 'block',
    marginTop:  theme.spacing(1)
  },
  colorBox: {
    width:'8rem'
  },
}));


export const PriceAndColor = ({ price, colorHex, colorName, companyName }) => {
  const classes = useStyles();
  return(
    <Box >
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box >
          <Text bold className={classes.price}>{formatPrice(price || 0)}</Text>
        </Box>
        <Box className={classes.colorBox}>
          <ColorBox hex={colorHex} color={colorName} />
        </Box>
      </Box>
      {companyName && <Text light className={classes.leyend}>{LEYEND}{companyName}</Text> }
    </Box>
  );
};



