import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tag from '../../common/tag.component';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  tagsMargin: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

const FilterTags = ({
  filters = [],
  onDelete = () => {},
}) => {
  return (
    <Box>
      <FilterTagsList filters={filters} onFilterDelete={onDelete}/>
    </Box>
  );
};

/*
  --- Filter structure ---
    {
      key: 'key',
      label: 'displayName'
    }
  ------------------------
*/
const FilterTagsList = ({ filters = [], onFilterDelete }) => {
  const classes = useStyles();

  return filters.map(
    (filter, index) => {
      const dataTestid = `${filter.key}-tag-id`;
      return (
        <Tag
          key={index}
          data-test-id={dataTestid}
          value={filter}
          label={filter.label}
          onDelete={onFilterDelete} className={classes.tagsMargin} />
      );
    }
  );
};

export default FilterTags;
