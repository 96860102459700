import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Router } from '@reach/router';
import { GET_USER_AUTH } from '../queries/getUserAuth';
import { AdminRoles } from '../api/authorization';
import PrivateRoute from '../components/common/PrivateRoute.component';
import AdminLayout from '../components/layouts/admin/AdminLayout.component';
import ReservationPageContainer from '../containers/reservation/ReservationPage.container';
import FinancingPageContainer from '../containers/admin-financing/FinancingPage.container';
import ProductsPageContainer from '../containers/products/ProductsPage.container';
import SuggestionPageContainer from '../containers/admin-suggestion/SuggestionPage.container';
import { goToIngresar } from '../api/navigation';
import { checkIfWasLoggedIn } from '../utils/hooks/useIsLoggedUser';

const AdminPage = ({ location, pageContext, title, page }) => {
  return (
    <AdminLayout
      title={title}
      location={location}
      pageContext={pageContext}>
      {page}
    </AdminLayout>
  );
};

const AdminRouter = ({ location, pageContext }) => {
  const { loading, error, data } = useQuery(GET_USER_AUTH,{fetchPolicy: 'network-only',onCompleted: (authData)=>checkIfWasLoggedIn((authData && authData.currentUser !== null))});
  
  if (loading) return <div>Loading</div>;
  if (error) return <div>Error: {JSON.stringify(error)}</div>;
  
  const isLoggedIn = !!data?.currentUser;
  
  if (isLoggedIn) {
    return (
      <Router basepath="/admin">
        <PrivateRoute
          allowed={AdminRoles}
          user={data.currentUser}
          path="/productos"
          title="Productos"
          location={location}
          pageContext={pageContext}
          component={AdminPage}
          page={<ProductsPageContainer location={location} user={data.currentUser} />}
        />
        {/* SE COMENTA MODULO DE RESERVAS YA QUE NO SE VA A UTILIZAR DESDE CARMUV */}
        {/* <PrivateRoute
          allowed={AdminRoles}
          user={data.currentUser}
          path="/reservas"
          title="Reservas"
          location={location}
          pageContext={pageContext}
          component={AdminPage}
          page={<ReservationPageContainer location={location} user={data.currentUser} />} /> */}
        <PrivateRoute
          allowed={AdminRoles}
          user={data.currentUser}
          path="/solicitudes"
          title="Solicitudes"
          location={location}
          pageContext={pageContext}
          component={AdminPage}
          page={<SuggestionPageContainer location={location} user={data.currentUser} />}
        />
        <PrivateRoute
          allowed={AdminRoles}
          user={data.currentUser}
          path="/financiacion"
          title="Financiaciones"
          location={location}
          pageContext={pageContext}
          component={AdminPage}
          page={<FinancingPageContainer location={location} user={data.currentUser} />}
        />
      </Router>
    );
  }
  goToIngresar();
  return <></>;
};

export default AdminRouter;
