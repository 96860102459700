import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles, withStyles } from '@material-ui/core';
import { insertParam } from '../../../api/navigation';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const useStyles = ({ hideFooter, childNumber }) => makeStyles( (theme) => ({
  root: {
    [`& > .table-container > .MuiPaper-root > div:nth-child(${childNumber})`]: {
      backgroundColor: theme.palette.grayScale.g0,
      borderTopLeftRadius: theme.borderRadius.main,
      borderTopRightRadius: theme.borderRadius.main,
      borderBottomRightRadius: hideFooter ? theme.borderRadius.main : 'none',
      borderBottomLeftRadius: hideFooter ? theme.borderRadius.main : 'none',
      boxShadow: '0px 2px 5px #7777771F',
      [theme.breakpoints.up('md')]: {
        padding: '1rem',
        boxShadow: hideFooter ? '1px 1px 2px #7777771F' : '1px -1px 2px #7777771F',
      }
    },
    '& .MuiTable-root': {
      '& .MuiTableRow-root': {
        border: 'none',
        '&:nth-child(even)': {
          backgroundColor: '#FFFFFF',
        },
        '&:nth-child(odd)': {
          backgroundColor: '#F6F6F6',
        },
      },
    },
    '& .MuiTableCell-root': {
      border: 'none'
    },
    '& .MuiPaper-elevation2':{
      boxShadow:'none'
    },
    '& .MuiPaper-root':{
      backgroundColor: 'transparent',
    },
    '& table':{
      padding: '2px !important',
      margin: 0,
    },
    '& th:first-child, & td:first-child':{
      paddingLeft: theme.spacing(2)
    },
    '& th:last-child, & td:last-child':{
      paddingRight: theme.spacing(2)
    },
    '& table .MuiTableCell-paddingNone': {
      padding: 0,
    },
    '& tfoot': {
      backgroundColor: theme.palette.grayScale.g0,
      borderBottomLeftRadius: theme.borderRadius.main,
      borderBottomRightRadius: theme.borderRadius.main,
      boxShadow: '0px 2px 5px #7777771F',
      [theme.breakpoints.up('md')]: {
        boxShadow: '1px 1px 2px #7777771F',
      }
    },
    '& tfoot .MuiTableCell-root': {
      border: 'none',
    },
    '& tfoot .MuiButtonBase-root': {
      [theme.breakpoints.down('sm')]: {
        padding: 6,
      }
    }
  },
}));

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
  },
}))((props) => <Tab disableRipple {...props} />);

const localization = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'por fila',
    labelRowsPerPage: 'Filas por página'
  },
  toolbar: {
    nRowsSelected: '{0} filas(s) seleccionadas',
    searchPlaceholder: '¿Qué estás buscando?',
    searchTooltip: '¿Qué estás buscando?',
  },
  header: {
    actions: 'Acciones'
  },
  body: {
    emptyDataSourceMessage: 'No se encontraron resultados',
    filterRow: {
      filterTooltip: 'Filtrar'
    }
  }
};

const TabsContainer = ({ currentTab, handleTab, tabs = [] }) => {

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleTab}
      aria-label="simple tabs"
      indicatorColor="primary"
    >
      {
        tabs.map((tab, index) => {
          return (
            <StyledTab
              label={tab}
              {...a11yProps(index)}
            ></StyledTab>
          );
        })
      }
    </Tabs>
  );
};

const Table = ({
  title = '' ,
  isMobile,
  columns,
  data,
  ToolbarComponent,
  options,
  actions,
  detailPanel,
  onSearchChange = () => ({}),
  currentTab,
  handleTab,
  tabs = [],
  ...otherProps }) => {
  const hideFooter = options?.paging === false;
  // TODO: move children number logic to useStyle
  const childNumber = options?.toolbar === false ? 1 : 2;
  const classes = useStyles({ hideFooter, childNumber })();
  return (
    <div className={classes.root}>
      <div className="table-container">
        <MaterialTable
          icons={tableIcons}
          localization={localization}
          title={title}
          columns={columns}
          data={data}
          actions={actions}
          onChangePage={(page) => insertParam('page', page)}
          onChangeRowsPerPage={(pageSize) => insertParam({ page: 0, pageSize })}
          onSearchChange={onSearchChange}
          options={{
            ...options,
            headerStyle:{
              backgroundColor:'#FFFFFF',
              borderBottom:0,
              fontWeight:600,
            },
            searchFieldStyle:{
              paddingTop: '12px',
              paddingBottom: '11px',
              paddingLeft: '12px',
              paddingRight: '12px',
              minWidth: isMobile && '75vw',
            },
            searchFieldVariant: 'outlined',
            searchFieldAlignment: isMobile ? 'left' : 'right',
            actionsColumnIndex: -1,
            detailPanelColumnAlignment: 'right',
          }}
          detailPanel={detailPanel}
          components={ToolbarComponent && {
            Toolbar: props => (
              <>
                <ToolbarComponent isMobile={isMobile} {...props} />
                {
                  Boolean(tabs.length) &&
                    <TabsContainer
                      currentTab={currentTab}
                      tabs={tabs}
                      handleTab={handleTab}
                    />
                }
              </>
            )
          }}
          {...otherProps}
        />
      </div>

    </div>
  );
};

Table.propTypes = {
};

export default Table;
