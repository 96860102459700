
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FinancingForm from './FinancingForm.component';
import Text from '../../../common/text.component';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FlashMessage from '../../../common/FlashMessage.component';
import Modal from '../../../common/Modal.component';
import FinancingActionButtons from './FinancingActionButtons.component';
import Card from '../../../common/Card.component';
import Link from '../../../common/Link.component';

const GO_BACK_TEXT = 'Volver';

const useStyles = makeStyles( theme => ({
  actionButtonsDesktop:{
    width:'18rem',
    marginTop: theme.spacing(8),
    marginLeft:'auto',
    marginRight: theme.spacing(1.5)
  },
  mainContainer:{
    padding:`0 ${theme.spacing(2)}px`,
  },
  backButton:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  flashMessageModal:{
    height: 'max-content',
    padding: theme.spacing(4)
  }
}));

const FinancingPanelDesktop = ({
  brands,
  loadingBrands,
  disableSaveButton,
  success,
  submitError,
  onContinue,
  onFieldChange,
  onCancel,
  onGoBack,
  onSave,
  validations,
  onLogoDropzoneError,
  onPdfDropzoneError,
  successTitle,
  fields
})=>{
  const classes = useStyles();
  const showFlashMessage =  (success || submitError);

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.backButton}>
        <Link inverted onClick={onGoBack}>
          <Box display="flex" alignItems="center">
            <ArrowBackIosIcon style={{ fontSize: 10 }}/>
            <Text small>{GO_BACK_TEXT}</Text>
          </Box>
        </Link>
      </Box>
      { showFlashMessage ?
        <Modal open onClose={onGoBack}>
          <Card className={classes.flashMessageModal}>
            <FlashMessage
              onContinue={onContinue}
              success={success}
              succesTitle={successTitle}
            />
          </Card>
        </Modal>
        :
        <>
          <FinancingForm
            onFieldChange={onFieldChange}
            brands={brands}
            fields={fields}
            validations={validations}
            loadingBrands={loadingBrands}
            onLogoDropzoneError={onLogoDropzoneError}
            onPdfDropzoneError={onPdfDropzoneError}/>
          <Box className={classes.actionButtonsDesktop}>
            <FinancingActionButtons
              disableSaveButton={disableSaveButton}
              onCancel={onCancel}
              onSave={onSave} />
          </Box>
        </>
      }
    </Box>
  );
};

export default FinancingPanelDesktop;
