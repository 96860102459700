import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import TotalResults from '../../../common/TotalResults.component';
import Button from '../../../common/button.component';
import { MTableToolbar } from 'material-table';

const BUTTON_TEXT = 'Nueva financiación';

const useStyles = makeStyles( theme => ({
  button: {
    padding: 20,
    fontSize: '1rem',
    width: '100%',
    marginTop: '1rem',

    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: 0,
      padding: 27,
      marginLeft: 10,
    }

  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',

      '& .MuiToolbar-root > .MuiFormControl-root': {
        minWidth: '275px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiToolbar-root': {
        paddingRight: 0,
        paddingLeft: 0,
        width: '100%',

        '& > div': {
          minWidth: '100%',
          paddingLeft: 0,
        }
      },
    },
  }
}));

const FinancingToolbar = ({
  onCreateFinancing,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-betweer">
        <Box flex="1">
          <Box className={classes.container}>
            <MTableToolbar {...props} />
            <Button
              primary
              className={classes.button}
              onClick={() => onCreateFinancing()}
            >
              {BUTTON_TEXT}
            </Button>
          </Box>
          <TotalResults
            isMobile={false}
            total={props.data.length}
          />
        </Box>
      </Box>

    </>
  );
};

export default FinancingToolbar;
