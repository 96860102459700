import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

export const AdminIcons = {
  'productos': DashboardOutlinedIcon,
  'reservas': ShoppingBasketOutlinedIcon,
  'financiacion': LocalAtmIcon,
  'solicitudes': FeedbackIcon,
  'default':DashboardOutlinedIcon
};

export default AdminIcons;
