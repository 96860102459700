import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_SUGGESTIONS } from '../../queries';
import { UPDATE_SUGGESTION } from '../../mutations';
import SuggestionTable from '../../components/admin/suggestions/table/SuggestionTable.component';
import LoadingModal from '../../components/common/LoadingModal.component';
import Text from '../../components/common/text.component';
import Box from '@material-ui/core/Box';
import SuggestionToolbar from '../../components/admin/suggestions/toolbar/SuggestionToolbar.component';
import SuggestionDetail from '../../components/admin/suggestions/detail/SuggestionDetail.component';
import { insertParam } from '../../api/navigation';
import queryString from 'query-string';

const ERROR_MESSAGE = '¡ Lo sentimos ! Ocurrió un error inesperado, intenta nuevamente más tarde.';

const SuggestionPageContainer = ({ location, user }) => {
  const [ selectedSuggestion, setSelectedSuggestion ] = useState(null);
  let { page, pageSize } = queryString.parse(location.search);
  if (page) {
    page = parseInt(page);
  }
  if (pageSize) {
    pageSize = parseInt(pageSize);
  }
  useEffect(() => {
    const filtersFromLocation = queryString.parse(location.search);
    const suggestion = data?.dataSheetChanges.find( suggestion => suggestion.id === filtersFromLocation?.suggestionId);
    if (suggestion) {
      setSelectedSuggestion(suggestion);
    } else {
      setSelectedSuggestion(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const { loading, error, data } = useQuery(GET_SUGGESTIONS,{
    onCompleted : (onCompleteData) => {
      const filtersFromLocation = queryString.parse(location.search);
      const suggestion = onCompleteData.dataSheetChanges.find( suggestion => suggestion.id === filtersFromLocation?.suggestionId);
      setSelectedSuggestion(suggestion);
    }
  });

  const [ SetDataSheetChange, { loading: updateLoading, error: updatedError } ] = useMutation(UPDATE_SUGGESTION, {
    onCompleted: ({ setDataSheetChange }) => {
      setSelectedSuggestion(setDataSheetChange);
    },
  });

  const handleSelectedSuggestion = (suggestion) => {
    updateSuggestion(suggestion);
  };

  const handleGoBack = () => {
    updateSuggestion(null);
  };

  const updateSuggestion = (suggestion) => {
    insertParam('suggestionId', suggestion?.id);
    if(suggestion) {
      const filteredSuggestion = suggestions.find(sug => sug.id === suggestion?.id );
      setSelectedSuggestion(filteredSuggestion);
    }else{
      setSelectedSuggestion(null);
    }
  };

  const handleOnStateChange = (id, status) => {
    SetDataSheetChange({ variables: { dataSheetChange: { id, status } } });
  };

  if(loading || updateLoading) return <LoadingModal />;

  if(error) return (
    <Box
      display="flex"
      flexDirection="row"
      alignContent="center"
      justifyContent="center"
    >
      <Box
        padding="4rem"
        textAlign="center"
        fontSize="1rem"
        display="box"
        maxWidth="30rem">
        <Text light>{ERROR_MESSAGE}</Text>
      </Box>
    </Box>
  );

  const suggestions = data?.dataSheetChanges || [];

  const Toolbar = (toolbarProps) =>
    <SuggestionToolbar
      {...toolbarProps}
    />;

  return(
    <>
      {selectedSuggestion ?
        <SuggestionDetail
          user={user}
          suggestion={selectedSuggestion}
          onStateChange={handleOnStateChange}
          onGoBack={handleGoBack}
        />
        :
        <SuggestionTable
          toolbar={Toolbar}
          data={suggestions}
          initialPage={page}
          pageSize={pageSize}
          goToDetail={handleSelectedSuggestion}
        />
      }
    </>
  );
};

export default SuggestionPageContainer;
