import React from 'react';
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos, } from '@material-ui/icons';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import '../../assets/slick/slick-theme.css';
import '../../assets/slick/slick.css';

const useStyle = makeStyles(
  (theme) => ({
    iconContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor:'transparent'
    },
    icon: {
      position: 'relative',
      zIndex: 99,
      color: theme.palette.secondary.main
    },
    iconThin: {
      color: theme.palette.grayScale.g400
    },
    overrideArrows: {
      '&:before':{
        display:'none'
      }
    },
    dots: {
      content: '\'\'',
      display: 'block',
      width: '0.7rem',
      height: '0.7rem',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 99,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grayScale.g400
    },
    dotsContainer: {
      marginTop: theme.spacing(-7),
      '& > li': {
        margin: 0
      },
      '& > li.slick-active':{
        '& > div': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main
        }
      }
    },
  })
);

const CarouselSlider = ({ onChange = () => {}, className, ...props }) => {
  const classes = useStyle();
  let isDragging = false;

  const defaultSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <ArrowSlider next thin={props.thinArrows}/>,
    prevArrow: <ArrowSlider prev thin={props.thinArrows}/>,
    dots: false,
    beforeChange: () => isDragging = true,
    afterChange: (index) => { isDragging = false; onChange(index);},
    appendDots: dots => (
      <div>
        <div className={classes.dotsContainer}> {dots} </div>
      </div>
    ),
    customPaging: i => (
      <div className={classes.dots}></div>),

  };

  const settings = { ...defaultSettings, ...props.settings };


  const handleClick = (event) => {
    if(isDragging) { event.stopPropagation();}
  };

  return (
    <div 
    className={className} 
    onClickCapture={handleClick}>
      <Slider {...settings}>
        {props.children}
      </Slider>
    </div>
  );
};


const ArrowSlider = ({ className, onClick, next, thin }) => {
  const classes = useStyle();
  const arrowIcon =  getArrowIcon(next,thin);
  return (
    <div className={clsx(className, classes.overrideArrows)} onClick={onClick} >
      <div className={classes.iconContainer}>
        <div className={clsx('arrowIcon',classes.icon,{ [classes.iconThin]: thin })}>
          {arrowIcon}
        </div>
      </div>
    </div>
  );
};

const getArrowIcon = (next, thin ) => {
  if(next){
    if(thin){
      return <ArrowForwardIos/>;
    }
    return <ArrowForward/>;
  }
  if(thin){
    return <ArrowBackIos/>;
  }
  return <ArrowBack/>;
};


export default CarouselSlider;
