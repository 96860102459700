import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import TextField from '../../../common/TextField.component';

const MaskedTextFieldErrorControl = ({ mask, guide, InputProps, errorText, funcValidError, maxLength, errorStyle ,...textFieldProps }) => {
  const [isValidValue, setIsValidValue] = React.useState(true);
  return (
    <TextField
      {...textFieldProps}
      inputProps={{ mask, guide, maxLength }}
      InputProps={{
        inputComponent: TextMask,
        ...InputProps
      }}
      helperText={isValidValue && errorText ? '' : errorText } 
      onChange={errorText && funcValidError ? (e) => funcValidError(e.target.value, setIsValidValue) : '' }
      className={isValidValue && errorStyle ? null : errorStyle}
    />
  );
};

MaskedTextFieldErrorControl.propTypes = {
  mask: PropTypes.any,
  guide: PropTypes.bool,
};

export default MaskedTextFieldErrorControl;

const TextMask = (props) => {
  const { inputRef,mask, guide = false, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={guide}
    />
  );
};

TextMask.propTypes = {
  inputRef: PropTypes.func,
  mask: PropTypes.array,
};

