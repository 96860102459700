import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import Button from '../../common/button.component';
import Link from '../../common/Link.component';
import Logo from '../../common/logo.component';


const APPLY_FILTERS_BUTTON = 'Aplicar filtros';

const useStyles = makeStyles( theme => ({
  container: {
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: '100%',
  },
  appBar: {
    position: 'relative',
    background: theme.palette.grayScale.g0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grow: {
    margin: 'auto',
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display: 'flex',
  },
  formulario: {
    justifyContent:'center',
    width: '42rem',
    margin: 'auto',
  },
  button: {
    width: '12.5rem',
    textTransform: 'none',
    justifySelf: 'flex-end',
    marginTop: theme.spacing(2)
  },

  select: {
    margin: '2rem',
  },
  closeButton: {
    fontSize: '1.25rem',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.borderRadius.main,
    }
  }
}));

const ApplyFiltersDialog = ({
  children,
  open,
  onClose,
  onApplyFilters
}) => {
  const classes = useStyles();

  return(
    <Dialog
      PaperProps={{
        className: classes.paper
      }}
      fullScreen
      scroll="paper"
      open={open}
      disableEscapeKeyDown
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            onClick={onClose}
            aria-label="close">
            <ArrowBackIosIcon />
          </IconButton>
          <Grid className={classes.grow}>
            <Link to='/' className={classes.logo}>
              <Logo className={classes.logo}/>
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
      {children}
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="center">
          <Button
            onClick={onApplyFilters}
            className={classes.button}>
            {APPLY_FILTERS_BUTTON}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyFiltersDialog;
