import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Divider,
  useMediaQuery,
  DialogActions,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import Link from '../../../common/Link.component';
import Text from '../../../common/text.component';
import Card from '../../../common/Card.component';
import Select from '../../../common/Select.component';
import { ReserveStatus } from '../../system/ReserveStatus.enum';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '../../../common/button.component';
// eslint-disable-next-line max-len
import SuggestionEmailDialogContainer from '../../../../containers/admin-suggestion/SuggestionEmailDialog.container';
import { useTheme } from '@material-ui/core/styles';
import LoginSection from '../../../header/LoginSection.component';

const BUTTON_SEND_EMAIL = 'Enviar mail';
const TITLE = 'Solicitud de mejora';
const BACK_TEXT = 'Volver';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize:'1rem'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.grayScale.g0,
    color: theme.palette.text.primary,
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
    alignItems:'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '35rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem'
    }
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: '2rem 1rem 0',
  },
  sectionData: {
    paddingBottom: '1.8rem',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
    }
  },
  link: {
    minHeight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'center',

    '& button': {
      width: '100%'
    }
  }
}));

const SectionData = ({ label, value, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionData}>
      <Box marginBottom="0.5rem">
        <Text bold>{label}</Text>
      </Box>
      <Box>
        { children || <Text light small>{value || '--'}</Text> }
      </Box>
    </Box>
  );
};

const SuggestionCard = ({ elevation, suggestion, onStateChange }) => {
  const classes = useStyles();
  const states = Object.keys(ReserveStatus);
  const initialValue = Object.keys(ReserveStatus).find(key => ReserveStatus[key] === suggestion.status);

  return (
    <Card className={classes.card} elevation={elevation}>
      <Box className={classes.section}>
        <SectionData label="Solicitante" value={suggestion.applicant} />
        <SectionData label="Email" value={suggestion.applicantEmail} />
      </Box>
      <Divider />
      <Box className={classes.section}>
        <SectionData label="Marca" value={suggestion.brand} />
        <SectionData label="Modelo" value={suggestion.model} />
        <SectionData label="Versión" value={suggestion.version} />
      </Box>
      <Divider />
      <Box className={classes.section}>
        <SectionData label="Descripción de la solicitud" value={suggestion.description} />
      </Box>
      <Divider />
      <Box className={classes.section}>
        <SectionData label="Estado">
          <Select
            id='select-state'
            label={suggestion.status}
            value={initialValue}
            onChange={(value) => onStateChange(suggestion.id,value)}
            options={states.map( (state) => ({
              label: ReserveStatus[state],
              value: state,
            }))}/>
        </SectionData>
      </Box>
    </Card>
  );
};

const SuggestionDetail = ( { suggestion, onGoBack, user, onStateChange } ) => {
  const classes = useStyles();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { applicantFirstName, applicantLastName, status, ...moreInfo } = suggestion;
  const formattedSuggestion = {
    applicant: `${applicantFirstName} ${applicantLastName}`,
    status: ReserveStatus[status],
    ...moreInfo,
  };

  return (
    <>
      {isMobile ?
        <>
          <Dialog fullScreen={true} scroll="paper" open={true} onClose={onGoBack} disableEscapeKeyDown>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={onGoBack}
                  className={classes.menuButton}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <Text light className={classes.title}>
                  {TITLE}
                </Text>
              </Toolbar>
              <LoginSection isDesktop isLoggedIn profile={user.profile}/>
            </AppBar>
            <DialogContent dividers>
              <SuggestionCard
                elevation={0}
                suggestion={formattedSuggestion}
                onStateChange={onStateChange}
              />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button primary onClick={() => setOpenEmailDialog(true)}>{BUTTON_SEND_EMAIL}</Button>
            </DialogActions>
          </Dialog>
        </> :
        <>
          <Box className={classes.link}>
            <Link inverted onClick={onGoBack}>
              <Box display="flex" alignItems="center">
                <ArrowBackIosIcon style={{ fontSize: 10 }}/>
                <Text small>{BACK_TEXT}</Text>
              </Box>
            </Link>
            <Button primary onClick={() => setOpenEmailDialog(true)}>{BUTTON_SEND_EMAIL}</Button>
          </Box>
          <SuggestionCard
            elevation={1}
            suggestion={formattedSuggestion}
            onStateChange={onStateChange}
          />
        </>
      }
      { openEmailDialog &&
      <SuggestionEmailDialogContainer
        open={openEmailDialog}
        id={suggestion.id}
        onClose={() => setOpenEmailDialog(false)}
        onBack={onGoBack}
      />
      }
    </>
  );
};

export default SuggestionDetail;
