import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyle = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justify: 'space-evenly',
      direction: 'row',
    },
    infoAlert: {
      width: '100%',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      boxShadow: theme.shadow.bottom,
      marginBottom: theme.spacing(3),
      borderRadius: theme.borderRadius.large
    },
    alertMessage: {
      display: 'flex',
      alignItems: 'center',
    },
    bigIcon: {
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.xLarge,
      },
      alignItems: 'center',
    },
  })
);

const Notification = ({ text, ...otherProps }) => {
  const classes = useStyle();
  const [showInfo, setShowInfo] = useState(true);

  return (
    <>
      { showInfo &&
      <Box className={classes.root}>
        <Alert
          variant="outlined"
          severity="info"
          classes={{
            root: classes.infoAlert,
            message: classes.alertMessage,
            icon: classes.bigIcon,
            action: classes.bigIcon
          }}
          onClose={() => setShowInfo(false)}
          {...otherProps}
        >
          <Box display="flex">
            {text}
          </Box>
        </Alert>
      </Box>
      }
    </>
  );
};

export default Notification;
