import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_FINANCINGS, GET_BRANDS } from '../../queries';
import { DELETE_FINANCING } from '../../mutations';
import FinancingTable from '../../components/admin/financing/table/FinancingTable.component';
import LoadingModal from '../../components/common/LoadingModal.component';
import Text from '../../components/common/text.component';
import Box from '@material-ui/core/Box';
import FinancingToolbar from '../../components/admin/financing/toolbar/FinancingToolbar.component';
import FinancingDeleteDialog from '../../components/admin/financing/delete-dialog/FinancingDeleteDialog.component';
import { insertParam } from '../../api/navigation';
import FinancingPanelContainer from './FinancingPanel.container';
import queryString from 'query-string';
import { useMutation } from '@apollo/react-hooks';

const ERROR_MESSAGE = '¡ Lo sentimos ! Ocurrió un error inesperado, intenta nuevamente más tarde.';
const FINANCING_ID_KEY = 'financingId';

const FinancingPageContainer = ({
  location,
  user
}) => {
  const [ selectedFinancing, setSelectedFinancing ] = useState(null);
  const [ financingForDelete, setFinancingForDelete ] = useState(null);
  const [ showFinancingForm, setShowFinancingForm ] = useState(false);
  const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
  const [ deleteSuccess, setDeleteSuccess ] = useState(false);
  let { page, pageSize } = queryString.parse(location.search);
  if (page) {
    page = parseInt(page);
  }
  if (pageSize) {
    pageSize = parseInt(pageSize);
  }
  const { data: brandsData, loading: loadingBrands } = useQuery(GET_BRANDS);
  const brands = brandsData?.brands || [];
  const { loading, error, data, refetch } = useQuery(GET_ALL_FINANCINGS,{
    onCompleted : (onCompleteData) => {
      // update selected financing if financingId is set in query params when fetching all financings
      const filtersFromLocation = queryString.parse(location.search);
      const id = filtersFromLocation[FINANCING_ID_KEY];
      const financing = onCompleteData.financing.find( (fin) => fin.id === id);
      if(financing){
        setSelectedFinancing(financing);
        setShowFinancingForm(true);
      }
    }
  });

  const [deleteFinancing,
    { loading: deleteLoading,
      error: deleteError,
    }] = useMutation(DELETE_FINANCING, {
    onCompleted(success) {
      if(success){
        setDeleteSuccess(true);
        refetch();
      }
    }
  });

  useEffect(() => {
    const filtersFromLocation = queryString.parse(location.search);
    // const financing =  { ...selectedFinancing };
    const id = filtersFromLocation[FINANCING_ID_KEY];
    const financing = data?.financing.find( (fin) => fin.id === id);

    if (financing) {
      setSelectedFinancing(financing);
      setShowFinancingForm(true);
    } else {
      setSelectedFinancing(null);
      setShowFinancingForm(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleSelectFinancing = (financing) => {
    updateFinancingId(financing?.id);
    setSelectedFinancing(financing);
    setShowFinancingForm(true);
  };


  const updateFinancingId = (financingId) => {
    insertParam(FINANCING_ID_KEY, financingId);
  };

  const handleDeleteFinancing = (financing) => {
    setOpenDeleteDialog(true);
    setFinancingForDelete(financing);
  };

  const onCreateFinancing = () => {
    setShowFinancingForm(true);
    setSelectedFinancing(null);
  };

  if(loading) return <LoadingModal />;

  if(error) return (
    <Box
      display="flex"
      flexDirection="row"
      alignContent="center"
      justifyContent="center"
    >
      <Box
        padding="4rem"
        textAlign="center"
        fontSize="1rem"
        display="box"
        maxWidth="30rem">
        <Text light>{ERROR_MESSAGE}</Text>
      </Box>
    </Box>
  );


  const handleFormBack = () => {
    setShowFinancingForm(false);
    refetch();
    updateFinancingId(null);
  };

  const handleOnContinueFromDelete = () => {
    closeDeleteDialog();
    refetch();
  };

  const closeDeleteDialog = () => {
    setFinancingForDelete(null);
    setOpenDeleteDialog(false);
    setDeleteSuccess(false);
  };

  const handleOnConfirmDelete = () => {
    deleteFinancing( { variables: { id: financingForDelete.id } });
  };

  const financings = data?.financing.map(financing => ({
    ...financing,
    pdfName: financing.pdf.substr(financing.pdf.lastIndexOf('/') + 1)
  })) || [];

  const Toolbar = (toolbarProps) =>
    <FinancingToolbar
      onCreateFinancing={onCreateFinancing}
      {...toolbarProps}
    />;

  return(
    <>
      {
        !loadingBrands && showFinancingForm ?
          <FinancingPanelContainer
            fields={selectedFinancing}
            brands={brands}
            loadingBrands={loadingBrands}
            editMode={!!selectedFinancing}
            onGoBack={handleFormBack}/>
          :
          <FinancingTable
            toolbar={Toolbar}
            data={financings}
            initialPage={page}
            pageSize={pageSize}
            onSelectFinancing={handleSelectFinancing}
            onDeleteFinancing={handleDeleteFinancing}
          />
      }
      { openDeleteDialog &&
        <FinancingDeleteDialog
          open={openDeleteDialog}
          onClose={closeDeleteDialog}
          onConfirm={handleOnConfirmDelete}
          financing={financingForDelete}
          onContinue={handleOnContinueFromDelete}
          success={deleteSuccess}
          loading={deleteLoading}
          submitError={deleteError} />
      }
    </>
  );

};

export default FinancingPageContainer;
