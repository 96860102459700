import React from 'react';
import Box from '@material-ui/core/Box';
import Text from './text.component';
import Link from './Link.component';
import { makeStyles } from '@material-ui/core';

const TEXT_RESULTS = 'resultados en vista';
const TEXT_ONE_RESULT = 'resultado en vista';
const TEXT_FILTER = 'Filtrar';


const useStyles = makeStyles( theme => ({
  container:{
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  text:{
    fontSize:'1rem'
  }
}));

const TotalResults = ({ isMobile, total = 0, onShowFilters }) => {
  const classes = useStyles();
  const textResults = +total !== 1 ? TEXT_RESULTS : TEXT_ONE_RESULT;
  const justifyContent = isMobile ? 'space-between' : 'flex-end';
  return(
    <Box display="flex" className={classes.container} justifyContent={justifyContent}>
      <Text className={classes.text}>{`${total} ${textResults}`}</Text>
      {isMobile &&
        <Link
          inverted
          data-test-id="show-filter-link"
          onClick={() => onShowFilters()}>
          {TEXT_FILTER}
        </Link>
      }
    </Box>
  )
  ;
};

export default TotalResults;
