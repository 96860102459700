import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../common/text.component';
import Chip from '../../common/Chip.component';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CompanyName from './CompanyName.component';
import { downloadFile } from '../../../utils/download.utils';
import Link from '../../common/Link.component';

const BRANDS_TITLE = 'Marcas asociadas';
const NO_BRAND_MESSAGE = 'No existen marcas asociadas.';
const DOWNLOAD_BUTTON_TEXT = 'Descarga documentación';

const usesStyle = makeStyles((theme) => ({
  nameContainer:{
    padding: `${theme.spacing(2)}px 0`,
    borderBottom: `1px solid ${theme.palette.grayScale.g200}`
  },
  brandName:{
    padding: theme.spacing(1)
  },
  brandChip:{
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  },
  noBrandFound:{
    fontSize:'0.7rem',
    display:'block',
    textAlign:'center',
    padding: `${theme.spacing(2)}px 0`,
    width:'100%'
  },
  brandsContainer:{
    flex: 1,
    minHeight:'8rem',
    maxHeight: '8rem',
    overflow: 'hidden',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
  },
  brandsTitle:{
    display:'block',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  downloadContainer:{
    borderTop: `1px solid ${theme.palette.grayScale.g200}`,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    cursor:'pointer',
  },
})
);

const CompanyInfo = ({ company }) => {
  const classes = usesStyle();

  const downloadFinancing = () => {
    const fileName = `${company.name} - Financiacion.pdf`;
    downloadFile(company.pdf, fileName);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      {company.logo && <CompanyName name={company.name} className={classes.nameContainer} />}
      <Box className={classes.brandsContainer}>
        <AsociatedBrands company={company}/>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        textAlign='center'
        justifyContent='center'
        className={classes.downloadContainer}
        onClick={() => downloadFinancing()}>
        <Link inverted>{DOWNLOAD_BUTTON_TEXT}</Link>
      </Box>
    </Box>
  );
};

export const AsociatedBrands = ({ company }) => {
  const classes = usesStyle();

  return (
    <>
      <Text light smaller className={classes.brandsTitle}>{BRANDS_TITLE}</Text>
      <Brands company={company} />
    </>
  );
};


const Brands = ({ company }) => {
  const classes = usesStyle();
  if(company.brands && company.brands.length > 0){
    return company.brands.map(
      (brandItem) =>   <Chip
        key={`${brandItem.name}-brandItem`}
        className={classes.brandChip}
        label={<Text className={classes.brandName} >{brandItem.name}</Text>}
      />);
  }
  return <Text light className={classes.noBrandFound}>{NO_BRAND_MESSAGE}</Text>;
};

CompanyInfo.prototype = {
  company: PropTypes.object,
};

CompanyInfo.defaultProps = {
  company: {},
};

export default CompanyInfo;
