import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TextField from '../../../common/TextField.component';
import Text from '../../../common/text.component';
import Radio from '../../../common/radio.component';
import InformationCard from '../../../CarDetail/informationCard/InformationCard.component';
import { PriceAndColor } from '../../../reserva/PriceAndColor.component';
import clsx from 'clsx';
import { validateIsRequired } from '../../../../utils/validators.utils';

const LABEL_COMMENT = 'Describa el motivo de cancelación';
const LABEL_HELPER_TEXT = 'Debe ingresar un motivo de cancelacion';
const LABEL_APPLICANT = 'Solicitante ';
const LABEL_APPROVED = 'Aprobado ';
const LABEL_CANCELED = 'Cancelada';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    width: '100%',
    marginTop: theme.spacing(4)
  },
  textInput: {
    width:'100%'
  },
  applicantContainer: {
    marginTop: theme.spacing(2)
  },
  applicant: {
    fontSize:'0.8rem',
  },
  applicantFullName: {
    fontSize:'1rem',
    paddingLeft: theme.spacing(1)
  },
  information: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grayScale.g100}`,
    borderRadius: theme.borderRadius.main,
  },
  applicantDetail: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  radio: {
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    width:'50%'
  },
  radioLabel: {
    display: 'block',
    textAlign:'center',
    fontSize: '1.1rem',
    padding: 0
  },
  approved: {
    color: theme.palette.success.main
  },
  radioItem: {
    marginLeft: theme.spacing(2)
  }
}));

const ReservationFinalizationForm = ({
  submitted,
  fields,
  onFieldChanges,
  onError,
  reserve,
  logo
}) => {
  const classes = useStyles();
  const [approved, setApproved] = useState(true);
  const [error, setError] = React.useState(null);

  const handleInputChanges = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;

    if (value === null) delete newFields[key];

    validateForm(newFields);
    onFieldChanges(newFields);
  };

  const validateForm = (newFields) => {
    if(!newFields.approved) {
      const isReasonValid = validateIsRequired(newFields.reason);
      setError(isReasonValid);
      onError(!isReasonValid.valid);
    } else {
      setError(null);
    }
  };

  const handleApproveChange = (value) => {
    setApproved(value);
    handleInputChanges('approved',value);
  };

  const applicantFullName = `${reserve.applicantFirstName} ${reserve.applicantLastName}`;

  return(
    <>
      <Box className={classes.information} >
        <InformationCard
          versionName={reserve.version.name}
          brandName={reserve.brand}
          modelName={reserve.model}
          logo={logo}
          isLoggedIn/>
        <Box className={classes.applicantDetail}>
          <PriceAndColor
            price={reserve.price}
            colorHex={reserve.hexColor}
            colorName={reserve.vehicleColor}
          />
          <Text bold className={classes.applicant}>{LABEL_APPLICANT}</Text>
          <Text light className={classes.applicantFullName}>{applicantFullName}</Text>
        </Box>
      </Box>
      <Box className={classes.formContainer}>
        <Box className={classes.radio}>
          <Radio
            checked={approved}
            onChange={() => handleApproveChange(true)}
            className={classes.radioItem}
          />
          <Text light className={clsx(classes.radioLabel, classes.approved)}>{LABEL_APPROVED}</Text>
        </Box>
        <Box className={classes.radio}>
          <Radio
            checked={!approved}
            onChange={() => handleApproveChange(false)}
            className={classes.radioItem}
          />
          <Text light className={classes.radioLabel}>{LABEL_CANCELED}</Text>
        </Box>
      </Box>
      { !approved &&
      <TextField
        multiline
        rows={6}
        data-test-id="input-comment"
        tabIndex="1"
        inputProps={{
          maxLength: 255,
        }}
        error={submitted && error && !error.valid}
        helperText={submitted && !error.valid && LABEL_HELPER_TEXT}
        className={classes.textInput}
        label={LABEL_COMMENT}
        value={fields.comment}
        onChange={(evt) => handleInputChanges( 'reason', evt.target.value)}
        variant="outlined"
      />
      }
    </>
  );
};


export default ReservationFinalizationForm;
