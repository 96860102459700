import React from 'react';
import { makeStyles } from '@material-ui/core';
import Text from '../../../common/text.component';
import Image from '../../../common/Image.component';
import { AsociatedBrands } from '../../../financing/components/CompanyInfo.component';

const useStyles = makeStyles(theme => ({
  title: {
    display:'block',
    textAlign: 'center',
    fontSize:'0.9rem',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  header: {
    width: '90%',
    margin: 'auto',
    backgroundSize:'contain'
  }
}));

const FinancingSumary = (
  {
    financing
  }) => {
  const classes = useStyles();
  return (
    <>
      {financing.logo && <Image image={financing.logo} className={classes.header}/>}
      <Text className={classes.title} bold>{financing.name}</Text>
      <AsociatedBrands company={financing}/>
    </>
  );
};


export default FinancingSumary;
