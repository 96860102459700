import React from 'react';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import Text from '../../../../components/common/text.component';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
  boxContent:{
    display:'flex',
    alignItems:'center'
  },

  infoUsado:{
    background: '#F7F7F7',
    padding: '1rem',
    display:'inline-block',
    margin: '12px',
    borderRadius: '10px',
    align: 'center',
    maxHeight:'50.67px'
  },

  infoUsadoLocation:{
    background: '#F7F7F7',
    padding: '1rem',
    display:'flex',
    margin: '12px',
    borderRadius: '10px',
    alignItems: 'center',
    maxHeight:'50.67px'
  }
}));

const InformationCardUsado = ({ year, domain, km, location }) => {

  const classes = useStyles();
 
  const YEAR_LEYEND = 'Año';
  const DOMAIN_LEYEND = 'Patente';
  const KM_LEYEND = 'Kms.';
  const yearUsado = year;
  const domainUsado = domain;
  const kmUsado = km;
  const locationUsado = location;
  return (
    <>
        <Box className={classes.boxContent}>
            <Box className={classes.infoUsado}>
                <Text light>{`${DOMAIN_LEYEND} ${domainUsado}`}</Text>
            </Box>
            <Box className={classes.infoUsado}> 
                <Text light>{`${YEAR_LEYEND} ${yearUsado}`}</Text>
            </Box>
            <Box className={classes.infoUsado}>
                <Text light>{`${KM_LEYEND} ${kmUsado}`}</Text>
            </Box>
            <Box className={classes.infoUsadoLocation}>
                <LocationOnIcon/>
                <Text light className={classes.textLocation}>{`${locationUsado}`}</Text>
            </Box>
        </Box>
    </>
  );
};

export default InformationCardUsado;