/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GET_BRANDS } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@material-ui/core';
import {
  validateArrayNotEmpty,
  validateIsRequired,
  validateInputText,
} from '../../utils/validators.utils';
import LoadingModal from '../../components/common/LoadingModal.component';
import { theme as globalTheme } from '../../components/testUtils';
import { useMutation } from '@apollo/react-hooks';
import { ADD_FINANCING, UPDATE_FINANCING } from '../../mutations';
import FinancingPanelDesktop from '../../components/admin/financing/form/FinancingPanelDesktop.component';
import FinancingPanelMobile from '../../components/admin/financing/form/FinancingPanelMobile.component';

const DROPZONE_TOO_LARGE_ERROR = 'file-too-large';
const DROPZONE_INVALID_TYPE_ERROR = 'file-invalid-type';
const SUCCES_TITLE = 'Financiación guardada con éxito';
const PDF_INVALID_TYPE_ERROR_MESSAGE = 'El archivo que intenta subir no es un .pdf. Por favor verifique y vuelva a intentarlo';
const FILE_TOO_LARGE_ERROR_MESSAGE = 'El archivo que intenta subir es demasiado grande. Por favor verifique y vuelva a intentarlo';
const FILE_INVALID_TYPE_ERROR_MESSAGE = 'El archivo que intenta subir no posee el formato correcto. Por favor verifique y vuelva a intentarlo';
const DEFAULT_FIELDS = {
  name: '',
  brands: [],
  active: false,
  logo: null,
  pdf: null
};
const DEFAULT_VALIDATIONS = {
  name: {
    valid: true,
    error: ''
  },
  brands: {
    valid: true,
    error: ''
  },
  logo: {
    valid: true,
    error: ''
  },
  pdf: {
    valid: true,
    error: ''
  }
};
const DEFAULT_CORRECT_VALIDATION = {
  valid: true,
  error: ''
};

const FinancingPanelContainer = ({
  fields: initialFields,
  brands,
  loadingBrands,
  onGoBack,
  editMode
}) => {
  const isMobile = useMediaQuery(globalTheme.breakpoints.down('sm'));
  const [fields, setFields] = useState(editMode ? initialFields : DEFAULT_FIELDS);
  const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);
  const [formHasError, setFormHasError] = useState(false);
  const [formIsComplete, setFormIsComplete] = useState(editMode ? isCompleteInEditMode(initialFields) : false);

  const mutation = editMode ? UPDATE_FINANCING : ADD_FINANCING;
  const [addOrModifyFinancing, { loading, data: success, error: submitError }] = useMutation(mutation);

  const validateFields = (newFields) => {
    const newValidations = {
      ...validations
    };
    if (newFields.name) {
      newValidations.name = validateInputText(newFields.name, 'El nombre es requerido', true, 50);
    }
    if (newFields.brands) {
      newValidations.brands = validateArrayNotEmpty(newFields.brands, 'Seleccione al menos una marca');
    }

    if(editMode){
      newValidations.logo = DEFAULT_CORRECT_VALIDATION;
      newValidations.pdf = DEFAULT_CORRECT_VALIDATION;
    } else {
      if (newFields.logo) {
        newValidations.logo = validateIsRequired(newFields.logo, 'El logo es requerido');
      }
      if (newFields.pdf) {
        newValidations.pdf = validateIsRequired(newFields.pdf, 'El pdf es requerida');
      }
    }

    setValidations(newValidations);

    return checkIfFormIsValid(newValidations);
  };

  const checkIfFormIsValid = (newValidations) => {
    const isFormValid =
        newValidations.name.valid &&
        newValidations.brands.valid &&
        newValidations.logo.valid &&
        newValidations.pdf.valid;

    setFormHasError(!isFormValid);
    return isFormValid;
  };

  const handleFieldChange = (key, value)=> {
    const newFields = { ...fields };
    newFields[key] = value;
    setFields(newFields);
    validateFields(newFields);
    checkIfFormIsComplete(newFields);
    if(key === 'logo') cleanDropzoneError('logo');
    if(key === 'pdf') cleanDropzoneError('pdf');
  };

  const handleOnSave = ()=> {
    const isFormValid = validateFields(fields);
    if(isFormValid) {
      submitForm();
    }
  };

  const submitForm = () => {
    const newFinancing = {
      id: fields.id,
      name: fields.name,
      brands: fields.brands.map(brnd => brnd.id).reduce((previous, actual) => previous.concat(actual), []),
      active: fields.active,
      logo: typeof fields.logo !== 'string' ? fields.logo : null,
      pdf: typeof fields.pdf !== 'string' ? fields.pdf : null,
    };
    addOrModifyFinancing({ variables: { financing: newFinancing } });
  };

  const cleanDropzoneError = (key) => {
    updateValidations(key, DEFAULT_CORRECT_VALIDATION);
  };

  const handleLogoDropzoneError = (error) => {
    const errorMessage = error.code === DROPZONE_TOO_LARGE_ERROR ?
      FILE_TOO_LARGE_ERROR_MESSAGE :
      FILE_INVALID_TYPE_ERROR_MESSAGE;

    const logoValidation = {
      valid: false,
      error: errorMessage
    };

    updateValidations('logo', logoValidation);
  };

  const handlePdfDropzoneError = (error) => {
    const errorMessage = error.code === DROPZONE_INVALID_TYPE_ERROR ?
      PDF_INVALID_TYPE_ERROR_MESSAGE :
      FILE_TOO_LARGE_ERROR_MESSAGE;

    const pdfValidation = {
      valid: false,
      error: errorMessage
    };

    updateValidations('pdf', pdfValidation);
  };

  const updateValidations = (key, validation) => {
    const newValidations = { ...validations };
    newValidations[key] = validation;
    setValidations(newValidations);
    checkIfFormIsValid(newValidations);
  };

  const checkIfFormIsComplete = (newFields) => {
    const isComplete = editMode ?
      isCompleteInEditMode(newFields) :
      isCompleteInRegularMode(newFields);

    setFormIsComplete(isComplete);
  };

  function isCompleteInRegularMode (newFields) {
    return  newFields.name &&
            newFields.pdf &&
            newFields.logo &&
            newFields.brands &&
            newFields.brands.length > 0;
  }

  function isCompleteInEditMode (newFields) {
    return  newFields.name &&
            newFields.brands &&
            newFields.brands.length > 0;
  }

  if(loading) return  <LoadingModal />;

  const disableSaveButton = !formIsComplete || formHasError;

  return (
    <>
      {isMobile  ?
        <FinancingPanelMobile
          brands={brands}
          fields={fields}
          disableSaveButton={disableSaveButton}
          onFieldChange={handleFieldChange}
          loadingBrands={loadingBrands}
          validations={validations}
          onSave={handleOnSave}
          onGoBack={onGoBack}
          onCancel={onGoBack}
          onContinue={onGoBack}
          submitError={submitError}
          success={success}
          successTitle={SUCCES_TITLE}
          onLogoDropzoneError={handleLogoDropzoneError}
          onPdfDropzoneError={handlePdfDropzoneError}
        />
        :
        <FinancingPanelDesktop
          brands={brands}
          fields={fields}
          disableSaveButton={disableSaveButton}
          onFieldChange={handleFieldChange}
          loadingBrands={loadingBrands}
          validations={validations}
          onSave={handleOnSave}
          onGoBack={onGoBack}
          onCancel={onGoBack}
          onContinue={onGoBack}
          submitError={submitError}
          success={success}
          successTitle={SUCCES_TITLE}
          onLogoDropzoneError={handleLogoDropzoneError}
          onPdfDropzoneError={handlePdfDropzoneError}
        />
      }
    </>
  );
};


FinancingPanelContainer.propTypes = {
  fields: PropTypes.object,
  onGoBack: PropTypes.func,
  editMode: PropTypes.bool,
};

FinancingPanelContainer.defaultProps = {
  fields: DEFAULT_FIELDS,
  onGoBack: () => {},
  editMode: false,
};

export default FinancingPanelContainer;
