/* eslint-disable max-len */
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Link from '../../../common/Link.component';
import Box from '@material-ui/core/Box';
import Table from '../../system/Table.component';
import Text from '../../../common/text.component';
import { ReserveStatus } from '../../system/ReserveStatus.enum';
import { desktopColumns, mobileColumns } from './TableConfiguration.constant';
const useStyles = makeStyles(theme => ({
  viewDetailLink: {
    borderTop: `1px solid ${theme.palette.grayScale.g200}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2rem',
  },
  mobileDetail: {
    backgroundColor: theme.palette.grayScale.g100,
  },
  tableReservations: {
    '& tbody>.MuiTableRow-root:hover': {
      backgroundColor: `${theme.palette.grayScale.g300} !important`,
      cursor: 'pointer',
    }
  },
}));

const ReservationTable = (
  { data = [],
    toolbar,
    initialPage = 0,
    pageSize = 10,
    goToReservationDetail,
  }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columns = isMobile ? mobileColumns : desktopColumns;
  const detailPanel = isMobile ? [
    {
      render: rowData => {
        return (
          <MobileDetail rowData={rowData} onOpenDetail={openDetail} />
        );
      },
      icon: () => <AddIcon />,
      openIcon: () => <RemoveIcon />,
    }
  ] : null;

  const actions = !isMobile ? [
    {
      icon: () => <VisibilityIcon color="primary"/>,
      onClick: (event, row) => openDetail(row.id)
    },
  ] : null;

  function openDetail(id) {
    const reservation = data.find(d => d.id === id);
    goToReservationDetail(reservation);
  }
  const tableData = data.map(
    (reserve) =>
    {
      const applicant = `${reserve.applicantFirstName} ${reserve.applicantLastName}`;
      const brand = reserve.brand;
      const model = reserve.model;
      const version = reserve.version?.name;

      const brandModelVersion = `${brand} \n ${model} \n ${version}`;
      const tableRow =  {
        applicant,
        brand,
        model,
        version,
        brandModelVersion,
        color: reserve.vehicleColor,
        status: ReserveStatus[reserve.state],
        id: reserve.id,
        applicantEmail: reserve.applicantEmail,
        carlotName: reserve.carLot?.name,
        condition: reserve.condition ? '0km' : 'Usado',
        createdAt: reserve.createdAt,
        applicantType: reserve.applicantType,
      };

      return tableRow;
    }
  );

  return (
    <div className={classes.tableReservations}>
      <Table
        columns={columns}
        detailPanel={detailPanel}
        actions={actions}
        ToolbarComponent={toolbar}
        data={tableData}
        isMobile={isMobile}
        options={{
          initialPage,
          pageSize
        }}
      />
    </div>
  );
};

const MobileDetail = ({ rowData, onOpenDetail }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mobileDetail}>
      <Box margin="15px">
        <Box><Text bold light>Solicitante</Text></Box>
        <Box><Text>{rowData.applicant}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Email</Text></Box>
        <Box><Text>{rowData.applicantEmail}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Color</Text></Box>
        <Box><Text>{rowData.color}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Concesionaria</Text></Box>
        <Box><Text>{rowData.carlotName}</Text></Box>
      </Box>
      <Box className={classes.viewDetailLink}>
        <Link inverted onClick={() => onOpenDetail(rowData.id)}>Ver en detalle</Link>
      </Box>
    </Box>
  );
};

export default ReservationTable;
