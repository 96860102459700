import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import FilterTags from '../../../system/FilterTags.component';
const useStyles = makeStyles( theme => ({
  tags: {
    marginTop: theme.spacing(3),
  },
}));

const ReservationFilterTags = ({
  filters,
  onUpdateFilters
}) => {
  const classes = useStyles();

  const tags = generateTags(filters);

  const handleFilterTagDelete = (tag) => {
    const newFilters = { ...filters };
    const nameKey = `${tag.key}Name`;
    delete newFilters[nameKey];
    delete newFilters[tag.key];
    onUpdateFilters(newFilters);
  };

  return(
    <Box className={classes.tags} >
      <FilterTags
        filters={tags}
        onDelete={handleFilterTagDelete}
      />
    </Box>
  );
};

const generateTags = (filters) => {
  const tags = [];

  if(filters.brandName) {
    const brandTag = {
      key: 'brand',
      label: filters.brandName
    };
    tags.push(brandTag);
  }

  if(filters.model) {
    const modelTag = {
      key: 'model',
      label: filters.model
    };
    tags.push(modelTag);
  }

  if(filters.state) {
    const stateTag = {
      key: 'state',
      label: filters.stateName
    };
    tags.push(stateTag);
  }

  return tags;
};

export default ReservationFilterTags;
