import React from 'react';
import Table from '../../system/Table.component';
import ColorBox from '../../../common/ColorBox.component';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Text from '../../../common/text.component';
import { formatPrice } from '../../../../utils/currency.utils';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import formatDate from '../../../../utils/DateFomatter.utils';
import MaskedTextFieldErrorControl from './MaskedTextFieldErrorControl.component';

const PARTICULAR = 'PARTICULAR';
const REVENTA = 'REVENTA';

const useStyles = makeStyles(theme => ({
  colorBox: {
    display: 'flex',
    alignItems: 'center',

    '& > .MuiBox-root': {
      width: '14px',
      height: '14px',
      border: '1px solid #C7C7C7',
      borderRadius: '50%',
      [theme.breakpoints.up('md')]: {
        width: '14px',
        height: '14px',
      },
    },
    '& > span': {
      marginLeft: '0.2rem',
      flexBasis: '70%',
      [theme.breakpoints.up('md')]: {
        flexBasis: '50%'
      },
    }
  },
  textfield: {
    marginTop: 0,
    '& .MuiFormHelperText-root': {
      marginLeft: '1.3rem !important',
      position: 'absolute',
      transform: 'translate(-1rem, 2rem) !important',
      fontWeight: '400',
      fontSize: '11px',
      lineHeight: '17px',
      color: 'blue !important',
    },
  },
  errorStyle: {
    '& .MuiFormHelperText-root':{
      color: 'red !important',
    },
    '& .MuiInputBase-input': {
      color: 'red !important', 
    },
    
  },
  colorRow: {
    width: '45px'
  },
  stockColumn: {
    textAlign:'right'
  },
  priceLabel: {
    minWidth: '125px',
    whiteSpace: 'nowrap'

  },
  columnPublicar: {
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  widthCheck: {
    width: '9rem'
  }
}));


const StockTable = ({
  data = [],
  editing = false,
  onVisibleChange = () => { /* TODO document why this arrow function is empty */  },
  onPriceChange = () => { /* TODO document why this arrow function is empty */  },
  currency,
  is0Km = false,
  priceParticular,
}) => {
  
  const classes = useStyles();
  const tableData = data.map(stock => {
    const row = {};
    row.id = stock.id || '';
    row.name = stock.color?.name || '';
    row.color = stock.color?.color || '';
    row.visible = stock.visible;
    row.stock = stock.count;
    row.price = formatPrice(stock.price, currency);
    row.observation = stock.observation;
    row.stateReseller = stock.stateReseller || '';
    row.statePublic = stock.statePublic || '';
    row.lastUpdateParticular = stock.lastStatePublicUpdate ?
      formatDate(new Date(stock.lastStatePublicUpdate),'Date') : '';
    row.lastUpdateReventa = stock.lastStateResellerUpdate ?
      formatDate(new Date(stock.lastStateResellerUpdate),'Date') : '';
    return row;
  });
  const priceLength = 10;
  const PRICE_MASK = [`${currency} `, ...(new Array(priceLength).fill(/\d/))];

  const columns = [
    {
      title: 'Color',
      field: 'color',
      render: rowData => <ColorBox className={classes.colorBox} color={rowData.name} hex={rowData.color} />,
      width: 'auto'
    },
    {
      title: 'Stock',
      field: 'stock',
      render: rowData => <Text className={classes.stockColumn}>{rowData.stock}</Text>,
      width: 'auto',
      align:'center'
    },
    {
      title: 'Publicar para:',
      field: 'visible',
      render: rowData => {
        return (
          <FormGroup className={classes.columnPublicar}>
            <FormControlLabel className={classes.widthCheck}
              control={<Checkbox
                key={rowData.id}
                defaultChecked={rowData.statePublic}
                onChange={(_evt, value) => onVisibleChange(value, rowData.id, PARTICULAR)}
                color="primary"
                inputProps={{ 'aria-label': 'particular checkbox' }}
                disabled={!editing}
              />}
              label={<div>
                <div>Particular</div>
                { rowData.lastUpdateParticular && rowData.statePublic &&  <div>{rowData.lastUpdateParticular}</div>}
              </div>}
            />
            <FormControlLabel className={classes.widthCheck}
              control={<Checkbox
                key={rowData.id + 1}
                defaultChecked={rowData.stateReseller}
                onChange={(_evt, value) => onVisibleChange(value, rowData.id, REVENTA)}
                color="primary"
                inputProps={{ 'aria-label': 'reventa checkbox' }}
                disabled={!editing}
              />}
              label={<div>
                <div>Reventa</div>
                { rowData.lastUpdateReventa && rowData.stateReseller && <div>{rowData.lastUpdateReventa}</div>}
              </div>}
            />
          </FormGroup>
        );
      },
      width: 'auto',
    },
    {
      title: 'Precio reventa',
      field: 'price',
      width: 'auto',
      headerStyle: {
        minWidth: '12em',
      },
      render: rowData => editing ?
        <MaskedTextFieldErrorControl
          mask={PRICE_MASK}
          defaultValue={rowData.price}
          errorText={`Debe ser menor o igual a ${priceParticular}`}
          errorStyle={classes.errorStyle}
          funcValidError = {(val, setIsValidValue) => { const isValid = parseInt(val.split(' ')[1]) <= priceParticular;
           setIsValidValue(isValid);
           isValid && onPriceChange(val, rowData.id)
          }} 
          className={classes.textfield}
          maxLength={priceLength + 2}
        /> :
        <span className={classes.priceLabel}>{rowData.price}</span>
    },
    {
      title: 'Observaciones',
      field: 'observation',
      render: rowData => <Text className={classes.capitalize}>{rowData.observation}</Text>,
      width: 'auto'
    },
  ];

  const columnsUsados = columns.filter(col => col.field !== 'stock');

  return (
    <Table
      columns={is0Km ? columns : columnsUsados}
      data={tableData}
      options={{
        search: false,
        paging: false,
        toolbar: false
      }}
    />
  );
};

export default StockTable;
