import React from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '../../../common/TextField.component';
const LABEL_EMAIL = 'E-mail';
const LABEL_COMMENT = 'Comentario (Opcional)';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: '100%',
  },
  textInput: {
    width:'100%'
  },
}));

const EmailForm = ({
  fields,
  error,
  onFieldChanges,
}) => {
  const classes = useStyles();

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields };
    newFields[key] = value;
    onFieldChanges(newFields);
  };

  return(
    <div className={classes.container}>
      <TextField
        error={error && !error.valid}
        helperText={error && error.error}
        data-test-id="input-email"
        className={classes.textInput}
        label={LABEL_EMAIL}
        value={fields.email}
        onChange={(evt) => handleTextFieldChange( 'email', evt.target.value)}
        variant="outlined"
        tabIndex={0}
      />
      <TextField
        multiline
        rows={6}
        data-test-id="input-comment"
        inputProps={{
          maxLength: 1500,
        }}
        tabIndex="1"
        className={classes.textInput}
        label={LABEL_COMMENT}
        value={fields.comment}
        onChange={(evt) => handleTextFieldChange( 'comment', evt.target.value)}
        variant="outlined"
      />
    </div>
  );
};

export default EmailForm;
