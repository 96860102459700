import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_CATALOG_BRANDS_BY_COMPANYID,
  GET_CATALOG_MODELS_BY_BRAND_NAME,
  GET_COMPANIES,
} from '../../queries';
import ProductsSelectors from '../../components/admin/products/toolbar/components/ProductsSelectors.component';
const ProductSelectorsContainer
 = ({
   filters: initialFilters,
   onUpdateFilters
 }) => {
   const [ filters, setFilters ] = useState(initialFilters);

   const { data: companyData, loading: loadingCompanies } = useQuery(GET_COMPANIES);
 
   const { data: brandData, loading: loadingBrands } = useQuery(GET_CATALOG_BRANDS_BY_COMPANYID, {
     variables: { companyId: filters.companyId }
    });
    
   const { data: modelData, loading: loadingModels } = useQuery(GET_CATALOG_MODELS_BY_BRAND_NAME, {
     variables: {
       brandName: filters.brandName,
       companyId: filters.companyId
      }
    });


   const companies = companyData?.companies || [];
   const brands = brandData?.catalogBrandsByCompanyId || [];
   const models = modelData?.catalogModelsByBrandName || [];
   const publishedOptions = [
     { label: 'Publicado', value: true },
     { label: 'No publicado', value: false },
   ];

   const userVisibilityOptions = [
     { label: 'Particular', value: 'statePublic&-stateReseller' },
     { label: 'Reventa', value: 'stateReseller&-statePublic' },
     { label: 'Ambos', value: 'statePublic&stateReseller' }
   ];

   const handleOnSelectCompany = (company) => {
     const newFilters = { ...filters };
     newFilters['company'] = company.name;
     newFilters['companyName'] = company.name;
     newFilters['companyId'] = company.id;
     delete newFilters['brand'];
     delete newFilters['brandName'];
     delete newFilters['brandId'];
     delete newFilters['model'];
     delete newFilters['modelName'];
     updateFilters(newFilters);
   };

   const handleOnSelectBrand = (brand) => {
     const newFilters = { ...filters };
     newFilters['brand'] = brand.name;
     newFilters['brandName'] = brand.name;
     newFilters['brandId'] = brand.id;
     delete newFilters['model'];
     delete newFilters['modelName'];

     updateFilters(newFilters);
   };

   const handleOnSelectModel = (model) => {
     const newFilters = { ...filters };
     newFilters['model'] = model.name;
     newFilters['modelName'] = model.name;

     updateFilters(newFilters);
   };

   const handleOnSelectPublished = (publishCarmuv) => {
     const newFilters = { ...filters };
     newFilters['publishCarmuv'] = publishCarmuv.value;
     delete newFilters['userVisibility'];
     delete newFilters['userVisibilityName'];

     updateFilters(newFilters);
   };

   const handleOnSelectUserVisibility = (visibility) => {
     const newFilters = { ...filters };
     newFilters['userVisibility'] = visibility.value;
     newFilters['userVisibilityName'] = visibility.label;

     updateFilters(newFilters);
   };

   const updateFilters = (newFilters) => {
     setFilters(newFilters);
     onUpdateFilters(newFilters);
   };
   return(
     <ProductsSelectors
       initialValues={filters}
       companies={companies}
       brands={brands}
       models={models}
       publishedOptions={publishedOptions}
       visibilityOptions={userVisibilityOptions}
       loadingCompanies={loadingCompanies}
       loadingBrands={loadingBrands}
       loadingModels={loadingModels}
       onSelectCompany={handleOnSelectCompany}
       onSelectBrand={handleOnSelectBrand}
       onSelectModel={handleOnSelectModel}
       onSelectPublished={handleOnSelectPublished}
       onSelectUserVisibility={handleOnSelectUserVisibility}
     />
   );
 };

export default ProductSelectorsContainer
;
