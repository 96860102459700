import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../../common/Link.component';
import AdminIcons from './AdminIcons.constants';

const useStyles = makeStyles((theme) => ({
  activeLink: {
    display:'flex',
    flexDirection:'row',
    color: theme.palette.primary.main,
    '& * > .MuiListItemIcon-root':{
      color: theme.palette.primary.main,
    },
    '&:after':{
      position: 'relative',
      top: '0.95rem',
      right: 0,
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRight: `10px solid ${theme.palette.primary.main}`,
      content: '\'\'',
    },
  },
  link: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover':{
      backgroundColor: 'transparent',
      '& * > .MuiListItemIcon-root':{
        color: theme.palette.primary.main,
      },
    },
  },
  listItem: {
    '&:hover':{
      backgroundColor: 'transparent',
    },
  }
})
);

const MenuItems = ({ items }) => {
  const classes = useStyles();
  return (
    <>
      <Divider/>
      <List>
        { items.map(
          (menuItem, index) => {
            const Icon = getIconByName(menuItem.iconName);
            return (
              <Link
                to={menuItem.link}
                activeClassName={classes.activeLink}
                className={classes.link}
                key={index} >
                <ListItem button className={classes.listItem}>
                  <ListItemIcon><Icon /></ListItemIcon>
                  <ListItemText primary={menuItem.name} />
                </ListItem>
              </Link>
            );
          } )}
      </List>
    </>
  );
};

const getIconByName = (name) => {
  const icon = AdminIcons[name];
  if (icon) return icon;
  return AdminIcons.default;
};

export default MenuItems;
