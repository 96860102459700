/* eslint-disable max-len */
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import SEO from '../../seo';
import { makeStyles } from '@material-ui/core/styles';
import { StaticQuery, graphql } from 'gatsby';
import AdminHeader from './AdminHeader.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_AUTH } from '../../../queries/getUserAuth';
import Copyright from '../../footer/contact-info/Copyright.component';
import { checkIfWasLoggedIn } from '../../../utils/hooks/useIsLoggedUser';
import setUserData from '../../../context/user/actions/setUserData';
import { useAppContext } from '../../../context/AppContext';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    margin: '0 auto',
    padding: theme.spacing(2),
    paddingTop: '4.5rem',
    width:'100%',
    background: 'transparent linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 21%, #F8F8F8 100%) 0% 0% no-repeat padding-box',
    minHeight: '100vh',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
  },
  slogan: {
    padding: '2rem 0',
    '& > span': {
      color: theme.palette.grayScale.g400,
    }
  }
}));

export const PureAdminLayout = ({ children, title, meta, data }) => {
  const { userDispatch } = useAppContext()
  const classes = useStyles();
  const { data: authData = {} } = useQuery(GET_USER_AUTH,{fetchPolicy: 'network-only',onCompleted: (loggedData)=>checkIfWasLoggedIn((loggedData && loggedData.currentUser !== null))});
  const { profile } = authData?.currentUser || {};
  const isLoggedIn = (authData && authData.currentUser !== null) || false;
  checkIfWasLoggedIn(isLoggedIn);
  useEffect(() => {
    setUserData(userDispatch, {profile, isLoggedIn});
  }, [profile])
  return (
    <Box className={classes.root}>
      <SEO title={title} meta={meta} />
      <AdminHeader
        title={title}
        isLoggedIn={isLoggedIn}
        profile={profile}
        adminMenu={data.site.siteMetadata.adminMenu}
      />
      <Box component='main' className={classes.content}>
        <Box>
          {children}
        </Box>
        <Box className={classes.slogan}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
};

export const  AdminLayout = (props) => {

  return (
    <StaticQuery
      query={graphql`
        query AdminMenuQuery {
          site {
            siteMetadata {
              adminMenu {
                name
                link
                iconName
              }
            }
          }
        }
      `}
      render={data => (
        <PureAdminLayout {...props} data={data} />
      )}
    />
  );
};

export default AdminLayout;
