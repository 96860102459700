import React from 'react';
import ProductsSelectorsContainer from '../../../../containers/products/ProductsSelectors.container';
import ProductsFilterTags from './components/ProductsFilterTags.component';
import FilterToolbar from '../../system/FilterToolbar.component';

const ProductsToolbar = ({
  isMobile,
  filters,
  onUpdateFilters,
  ...props
}) => {

  const SelectorContainer = ({ updateFilters = onUpdateFilters }) =>
    <ProductsSelectorsContainer
      filters={filters}
      onUpdateFilters={updateFilters}
    />;
  const FilterTagsComponent = () => <ProductsFilterTags filters={filters} onUpdateFilters={onUpdateFilters} />;

  return (
    <FilterToolbar
      isMobile={isMobile}
      filters={filters}
      onUpdateFilters={onUpdateFilters}
      SelectorContainer={SelectorContainer}
      FilterTagsComponent={FilterTagsComponent}
      {...props}/>
  );
};

export default ProductsToolbar;
