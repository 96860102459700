/* eslint-disable max-len */
import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Link from '../../../common/Link.component';
import Box from '@material-ui/core/Box';
import Table from '../../system/Table.component';
import Text from '../../../common/text.component';
import { ReserveStatus } from '../../system/ReserveStatus.enum';
import { desktopColumns, mobileColumns } from './TableConfiguration.constant';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  viewDetailLink: {
    borderTop: `1px solid ${theme.palette.grayScale.g200}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2rem',
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
  pending: {
    color: theme.palette.error.main
  },
  approved: {
    color: theme.palette.success.main
  },
  cancelled: {
    color: theme.palette.grayScale.g400
  },
  inReview: {
    color: theme.palette.info.main
  },
  mobileDetail: {
    backgroundColor: theme.palette.grayScale.g100,
  }
}));

const SuggestionTable = (
  { data = [],
    initialPage = 0,
    pageSize = 10,
    toolbar,
    goToDetail,
  }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const columns = isMobile ? mobileColumns : desktopColumns;
  const detailPanel = isMobile ? [
    {
      render: rowData => {
        return (
          <MobileDetail rowData={rowData} goToDetail={goToDetail} />
        );
      },
      icon: () => <AddIcon />,
      openIcon: () => <RemoveIcon />,
    }
  ] : null;

  const actions = !isMobile ? [
    {
      icon: () => <VisibilityIcon color="primary"/>,
      onClick: (event, row) => goToDetail(row)
    },
  ] : null;

  const tableData = data.map(
    (suggestion) =>
    {
      const applicant = `${suggestion.applicantFirstName} ${suggestion.applicantLastName}`;
      const brand = suggestion.brand;
      const model = suggestion.model;
      const version = suggestion.version;

      const brandModelVersion = `${brand} \n ${model} \n ${version}`;
      const tableRow =  {
        applicant,
        brand,
        model,
        version,
        brandModelVersion,
        status: ReserveStatus[suggestion.status],
        id: suggestion.id,
        description: suggestion.description,
      };

      return tableRow;
    }
  );

  return (
    <>
      <Table
        columns={columns}
        detailPanel={detailPanel}
        actions={actions}
        ToolbarComponent={toolbar}
        data={tableData}
        isMobile={isMobile}
        options={{
          initialPage,
          pageSize
        }}
      />
    </>
  );
};

const MobileDetail = ({ rowData, goToDetail }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mobileDetail}>
      <Box margin="15px">
        <Box><Text bold light>Solicitante</Text></Box>
        <Box><Text>{rowData.applicant}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Marca, modelo y versión</Text></Box>
        <Box><Text className={classes.preWrap}>{rowData.brandModelVersion}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Estado</Text></Box>
        <Box>
          <Text light small className={clsx( {
            [classes.pending]: rowData.status === ReserveStatus.PENDING,
            [classes.approved]: rowData.status === ReserveStatus.APPROVED,
            [classes.cancelled]: rowData.status === ReserveStatus.CANCELLED,
            [classes.inReview]: rowData.status === ReserveStatus.IN_REVIEW,
          } )}>{rowData.status}</Text>
        </Box>
      </Box>
      <Box className={classes.viewDetailLink}>
        <Link inverted onClick={() => goToDetail(rowData)}>Ver en detalle</Link>
      </Box>
    </Box>
  );
};

export default SuggestionTable;
