import React, { useState } from 'react';
import { makeStyles, Box, useMediaQuery, DialogContent, DialogActions } from '@material-ui/core';
import Text from '../../../common/text.component';
import ReservationFinalizationForm from './ReservationFinalizationForm.component';
import { theme as globalTheme } from '../../../testUtils';
import { LoadingMessage } from '../../../common/LoadingModal.component';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../../../common/button.component';
import Dialog from '../../../common/Dialog.component';
import ActionButtons from '../../../common/ActionButtons.component';
import FlashMessage from '../../../common/FlashMessage.component';


const MODAL_TITLE = 'Finalizar proceso de reserva';
const LOADING_LEYEND = 'Estamos actualizando la reserva';
const COMFIRM_BTN_TEXT = 'Confirmar';
const CANCEL_BTN_TEXT = 'Cancelar';
const CONTINUE_BTN_TEXT = 'Continuar navegando';
const SUCCESS_TITLE = 'Reserva actualizada con éxito.';
const ERROR_MESSAGE = '¡ Lo sentimos ! Ocurrió un error, intentá nuevamente más tarde.';
// eslint-disable-next-line max-len
const FORM_INITIAL_FIELDS = {
  approved: true,
  reason: null,
};

const useStyles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      minHeight: '28rem',
      width: '26rem'
    }
  },
  title: {
    display:'block',
    textAlign: 'center',
    fontSize:'1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  leyend: {
    display:'block',
    textAlign: 'center',
    fontSize:'1rem',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '16.25rem',
    minHeight: '3.3rem',
    textTransform: 'none',
    fontSize: '1.25rem',
  },
  buttonContainer: {
    paddingTop: theme.spacing(7),
    justifyContent: 'space-between'
  },
  header: {
    width: '90%',
    margin: 'auto'
  },
  closeButton: {
    fontSize: '1.25rem',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const ReservationFinalizationDialog = (
  {
    open,
    reserve,
    logo,
    submitError,
    onBack,
    onClose,
    loading,
    success,
    onConfirm,
    onContinue,
  }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(globalTheme.breakpoints.down('sm'));
  const [fields, setFields] = useState(FORM_INITIAL_FIELDS);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formHasError, setFormHasError] = useState(false);

  const handleOnFieldChanges = (newFields) => {
    if(newFields.approved) {
      newFields['reason'] = null;
      setFormHasError(false);
    }
    setFields(newFields);
    formSubmitted && setFormSubmitted(false);
  };

  const handleOnSubmit = () => {
    setFormSubmitted(true);
    if(!formHasError) {
      fields.id = reserve.id;
      onConfirm(fields);
    }
  };

  const handleOnError = (hasError) => {
    setFormHasError(hasError);
  };

  const handleClose = () => {
    clearDialog();
    if (success) {
      onBack();
    } else {
      onClose();
    }
  };

  const clearDialog = () => {
    setFields(FORM_INITIAL_FIELDS);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.dialog} dividers>
        { !fullScreen ? (
          <CloseIcon className={classes.closeButton} onClick={handleClose}/>
        ) : null }
        { loading ?
          <OnLoadingContent
            loadingLeyend={LOADING_LEYEND} />
          : ( success || submitError ) ?
            <FlashMessage
              onContinue={onBack}
              succesTitle={SUCCESS_TITLE}
              errorMessage={ERROR_MESSAGE}
              fullScreen={fullScreen}
              success={success}/>
            :
            <Box>
              <Text bold className={classes.title}>{MODAL_TITLE}</Text>
              <ReservationFinalizationForm
                submitted={formSubmitted}
                reserve={reserve}
                logo={logo}
                fields={fields}
                onError={handleOnError}
                onFieldChanges={handleOnFieldChanges} />
              { !fullScreen &&
              <Box className={classes.buttonContainer}>
                <ActionButtons
                  onCancel={onContinue}
                  confirmText={COMFIRM_BTN_TEXT}
                  cancelText={CANCEL_BTN_TEXT}
                  onConfirm={handleOnSubmit}
                />
              </Box>
              }
            </Box>
        }
      </DialogContent>
      { fullScreen && !loading &&
      <DialogActions>
        {!success ?
          (
            <ActionButtons
              onCancel={onContinue}
              confirmText={COMFIRM_BTN_TEXT}
              cancelText={CANCEL_BTN_TEXT}
              onConfirm={handleOnSubmit}
            />
          ) :
          (
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                data-test-id="confirmar-reserva-dialogBtn"
                onClick={onBack}
                outline>
                {CONTINUE_BTN_TEXT}
              </Button>
            </Box>
          )
        }
      </DialogActions>}
    </Dialog>
  );
};

const OnLoadingContent = ({ loadingMessage,  loadingLeyend }) => {
  return (
    <Box
      display='flex'
      justifyContent='center' alignItems='center'>
      <LoadingMessage
        message={loadingMessage}
        leyend={loadingLeyend} />
    </Box>
  );
};

export default ReservationFinalizationDialog;
