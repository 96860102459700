import React from 'react';
import { makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Box } from '@material-ui/core';
import FinancingForm from './FinancingForm.component';
import FinancingActionButtons from './FinancingActionButtons.component';
import BackAppBar from '../../../header/BackAppBar.component';
import Button from '../../../common/button.component';
import FlashMessage from '../../../common/FlashMessage.component';

const CONTINUE_BTN_TEXT = 'Continuar navegando';

const useStyles = makeStyles( theme => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      padding: '2rem 2.5rem 1.25rem',
      minHeight: '28rem',
      width: '26rem'
    }
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.borderRadius.main,
    }
  },
  dialogActions:{
    width:'100%',
    padding:`${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

const FinancingPanelMobile = ({
  brands,
  loadingBrands,
  disableSaveButton,
  success,
  submitError,
  onContinue,
  onFieldChange,
  onCancel,
  onGoBack,
  onSave,
  validations,
  onLogoDropzoneError,
  onPdfDropzoneError,
  successTitle,
  fields
})=>{
  const classes = useStyles();
  const showFlashMessage =  (success || submitError);

  return(
    <Dialog
      PaperProps={{
        className: classes.paper
      }}
      fullScreen
      scroll="paper"
      open
      disableEscapeKeyDown
    >
      <BackAppBar onBack={onGoBack}/>
      <DialogContent className={classes.dialog} dividers>
        { showFlashMessage ?
          <FlashMessage
            success={success}
            succesTitle={successTitle}
            fullScreen />
          :
          <FinancingForm
            onFieldChange={onFieldChange}
            brands={brands}
            fields={fields}
            validations={validations}
            loadingBrands={loadingBrands}
            onLogoDropzoneError={onLogoDropzoneError}
            onPdfDropzoneError={onPdfDropzoneError}/>
        }
      </DialogContent>
      <DialogActions>
        <Box className={classes.dialogActions}>
          { showFlashMessage ?
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                data-test-id="continue-button"
                onClick={() => onContinue()}
                outline>
                {CONTINUE_BTN_TEXT}
              </Button>
            </Box>
            :
            <FinancingActionButtons
              disableSaveButton={disableSaveButton}
              onCancel={onCancel}
              onSave={onSave} />
          }
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FinancingPanelMobile;
