import React from 'react';
import EmailDialog from '../../components/admin/system/email-dialog/EmailDialog.component';
import { useMutation } from '@apollo/react-hooks';
import { SEND_SUGGESTION_EMAIL } from '../../mutations';

const SuggestionEmailDialogContainer = ({ open, onBack, onClose, id }) => {

  const [SendSuggestionEmail, { loading, data, error }] = useMutation(SEND_SUGGESTION_EMAIL);

  const handleOnConfirm = (fields) => {
    const email = fields.email;
    const comments = fields.comment;
    SendSuggestionEmail({ variables: { id, email, comments } });
  };

  const success = !!data?.sendDataSheetChangeEmail.id || false;

  return (
    <EmailDialog
      open={open}
      submitError={error}
      onBack={onBack}
      onClose={onClose}
      loading={loading}
      success={success}
      onConfirm={handleOnConfirm}
      onContinue={onClose}
    />
  );
};

export default SuggestionEmailDialogContainer;
