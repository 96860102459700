import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_PRODUCTS } from '../../queries';
import LoadingModal from '../../components/common/LoadingModal.component';
import Text from '../../components/common/text.component';
import Table from '../../components/admin/system/Table.component';
import ProductsToolbar from '../../components/admin/products/toolbar/ProductsToolbar.component';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Link from '../../components/common/Link.component';
import { formaterNumber } from '../../utils/currency.utils';

const ERROR_MESSAGE = '¡ Lo sentimos ! Ocurrió un error inesperado, intenta nuevamente más tarde.';
const PUBLISHED  = 'Publicado';
const NOT_PUBLISHED = 'No Publicado';

const verifyColor = (data) => {
  let newColor = 'black';
  if(data === PUBLISHED){
    newColor = '#0E9B2F';
  }
  return  { color: newColor };
};

const desktopColumns0km = [
  { title: 'Empresa de origen', field: 'company', cellStyle:{ minWidth: '130px' } },
  { title: 'Concesionario', field: 'carlot' },
  { title: 'Marca', field: 'brand' },
  { title: 'Modelo', field: 'model' },
  { title: 'Versión', field: 'version' },
  { title: 'Estado Particular', field: 'statePublic', cellStyle: (data)=>verifyColor(data) },
  { title: 'Estado Reventa', field: 'stateReseller', cellStyle: (data)=>verifyColor(data) },
];

const desktopColumnsUsados = [
  { title: 'Empresa de origen', field: 'company', cellStyle:{ minWidth: '130px' } },
  { title: 'Concesionario', field: 'carlot' },
  { title: 'Patente', field: 'domain' },
  { title: 'Marca', field: 'brand' },
  { title: 'Modelo', field: 'model' },
  { title: 'Versión', field: 'version' },
  { title: 'Año', field: 'year' },
  { title: 'Kilómetros', field: 'km' },
  { title: 'Estado Particular', field: 'statePublic', cellStyle: (data)=>verifyColor(data) },
  { title: 'Estado Reventa', field: 'stateReseller', cellStyle: (data)=>verifyColor(data) },
];

const mobileColumns = [
  {
    title: 'Marca, modelo y versión',
    field: 'brandModelVersion',
    cellStyle: () => ({ whiteSpace: 'pre-wrap' }),
  }
];

const useStyles = makeStyles(theme => ({
  viewDetailLink: {
    borderTop: `1px solid ${theme.palette.grayScale.g200}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2rem',
  },
  mobileDetail: {
    backgroundColor: theme.palette.grayScale.g100,
  },
  iconVisibility:{
    fontSize:'1.25rem'
  }
}));

const ProductsTableContainer = ({ filters, onUpdateFilters, onSelectProduct, initialPage = 0, pageSize = 10 }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ columns, setColumns ] = React.useState(isMobile ? mobileColumns : desktopColumns0km);
  const [currentTab, setTab] = React.useState(0);
  // Nuevo = 0, usados = 1

  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: {
      model: filters.model,
      brand: filters.brand,
      companyId: filters.companyId,
      companyName: filters.companyName,
      carlotId: filters.carlotId,
      publishCarmuv: filters.publishCarmuv && filters.publishCarmuv === 'true',
      is0km: Boolean(!currentTab),
      userVisibility: filters.userVisibility
    },
    fetchPolicy: 'network-only'
  });

  const products = data?.products || [];

  const detailPanel = isMobile ? [
    {
      render: rowData => <MobileDetail rowData={rowData} onSelectProduct={onSelectProduct} />,
      icon: () => <AddIcon />,
      openIcon: () => <RemoveIcon />,
    }
  ] : null;

  const actions = !isMobile ? [
    {
      icon: () => <VisibilityOutlinedIcon className={classes.iconVisibility} color="primary"/>,
      onClick: (_e, row) => onSelectProduct(row)
    },
  ] : null;

  const tableData = products.map((product) => {
    const brand = product.brand.name;
    const model = product.model.name;
    const version = product.version.name;
    const stock = product.stockFisico ? 'Disponible' : 'No Disponible';
    const brandModelVersion = `${brand}\n${model}\n${version}`;
    const company = product.company?.name;
    const carlot = product.carLot?.name;
    const segment = product.segment?.name;
    return {
      segment,
      brand,
      model,
      version,
      brandModelVersion,
      id: product.id,
      stock,
      company,
      carlot,
      year: product?.year,
      km: `${formaterNumber(product?.km)}`,
      visible: product?.visible ? PUBLISHED : NOT_PUBLISHED,
      domain: product?.domain,
      stateReseller: product?.stateReseller ? PUBLISHED : NOT_PUBLISHED,
      statePublic: product?.statePublic ? PUBLISHED : NOT_PUBLISHED,
    };
  });

  const handleTab = (_event, newValue) => {
    setTab(newValue);
    setColumns(newValue === 1 ? desktopColumnsUsados : desktopColumns0km);
  };

  if(loading && currentTab === 0) return <LoadingModal />;

  if(error) return (
    <Box display="flex" flexDirection="row" alignContent="center" justifyContent="center">
      <Box padding="4rem" textAlign="center" fontSize="1rem" display="box" maxWidth="30rem">
        <Text light>{ERROR_MESSAGE}</Text>
      </Box>
    </Box>
  );


  const Toolbar = (toolbarProps) =>
    <ProductsToolbar
      filters={filters}
      onUpdateFilters={onUpdateFilters}
      {...toolbarProps}
    />;

  const tabs = ['0km', 'Usados'];

  return(
    <Table
      columns={columns}
      detailPanel={detailPanel}
      actions={actions}
      ToolbarComponent={Toolbar}
      data={tableData}
      isMobile={isMobile}
      tabs={tabs}
      handleTab={handleTab}
      currentTab={currentTab}
      isLoading={loading}
      options={{
        initialPage,
        pageSize
      }}
    />
  );

};

const MobileDetail = ({ rowData, onSelectProduct }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mobileDetail}>
      <Box margin="15px">
        <Box><Text bold light>Empresa</Text></Box>
        <Box><Text>{rowData.company}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Concesionario</Text></Box>
        <Box><Text>{rowData.carlot}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Segmento</Text></Box>
        <Box><Text>{rowData.segment}</Text></Box>
      </Box>
      <Box margin="15px">
        <Box><Text bold light>Stock</Text></Box>
        <Box><Text>{rowData.stock}</Text></Box>
      </Box>
      <Box className={classes.viewDetailLink}>
        <Link inverted onClick={() => onSelectProduct(rowData)}>Editar precio por color</Link>
      </Box>
    </Box>
  );
};

export default ProductsTableContainer;
