import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import FilterTags from '../../../system/FilterTags.component';

const useStyles = makeStyles( theme => ({
  tags: {
    marginTop: theme.spacing(3),
  },
}));

const ProductsFilterTags = ({
  filters,
  onUpdateFilters
}) => {
  const classes = useStyles();

  const handleFilterTagDelete = (tag) => {
    const newFilters = { ...filters };
    delete newFilters[tag.key];
    const nameKey = `${tag.key}Name`;
    delete newFilters[`${tag.key}Id`];
    delete newFilters[nameKey];

    // eslint-disable-next-line default-case
    switch(tag.key) {
      case 'company':
        delete newFilters['carlot'];
        delete newFilters['carlotName'];
        delete newFilters['carlotId'];
        break;
      case 'carlot':
        delete newFilters['brand'];
        delete newFilters['brandName'];
        delete newFilters['brandId'];
        break;
      case 'brand':
        delete newFilters['model'];
        delete newFilters['modelName'];
        break;
      case 'publishCarmuv':
        delete newFilters['userVisibility'];
        delete newFilters['userVisibilityName'];
        break;
    }

    onUpdateFilters(newFilters);
  };

  const tags = generateTags(filters);

  return(
    <Box className={classes.tags} >
      <FilterTags
        filters={tags}
        onDelete={handleFilterTagDelete}
      />
    </Box>
  );
};

const generateTags = (filters) => {
  const tags = [];

  if(filters.company) {
    const companyTag = {
      key: 'company',
      label: filters.companyName
    };
    tags.push(companyTag);
  }

  if(filters.carlot) {
    const carlotTag = {
      key: 'carlot',
      label: filters.carlotName
    };
    tags.push(carlotTag);
  }

  if(filters.brand) {
    const brandTag = {
      key: 'brand',
      label: filters.brandName
    };
    tags.push(brandTag);
  }

  if(filters.model) {
    const modelTag = {
      key: 'model',
      label: filters.modelName
    };
    tags.push(modelTag);
  }

  if(filters.publishCarmuv) {
    const publishedTag = {
      key: 'publishCarmuv',
      label: filters.publishCarmuv === "true" ? 'Publicado' : 'No publicado'
    };
    tags.push(publishedTag);
  }

  if(filters.userVisibility) {
    const visibilityTag = {
      key: 'userVisibility',
      label: filters.userVisibilityName
    };
    tags.push(visibilityTag);
  }

  return tags;
};

export default ProductsFilterTags;
